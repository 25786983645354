import React, { FC } from "react";
import { getWord } from "../../services/lang.services";
import "./CardHeader.scss";

interface CardHeaderProps {
  title: string;
  count: number;
}

const CardHeader: FC<CardHeaderProps> = (props: CardHeaderProps) => (
  <div className="CardHeader h-fit" data-testid="CardHeader">
    <div className="flex justify-between mt-2">
      <div className="text-cardTitle dark:text-textWhite font-bold ml-5 mt-1 mb-1">{getWord(props.title)}</div>
      <div className={`${props.title.toLowerCase() + "Card"} font-bold px-6 pt-1 pb-1 mr-2  mb-1 rounded-lg `}>{props.count}</div>
    </div>
    <div className={`${props.title.toLowerCase() + "Card"} border-2 rounded-l`}></div>
  </div>
);

export default CardHeader;
