import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { FC, useState } from "react";
import { CM_PADIPATHARKU_DATA } from "../../api/helpers/DataQueryKeys";
import { getCmPadipatharku } from "../../api/MediaServices";
import { Cm_Padipatharku } from "../../utils/constants";
import { getUserSelectedDate } from "../../utils/dateHelper";
import { showLoadingIcon } from "../../utils/helper";
import TopTrending from "../TopTrending/TopTrending";
import "./CmPadipatharku.scss";

interface ICmPadipatharku {}

const CmPadipatharku: FC<ICmPadipatharku> = (props: ICmPadipatharku) => {
  const [userDate] = useState(getUserSelectedDate());
  const [date] = useState(moment(userDate).format("YYYY-MM-DD"));

  const { data, isLoading } = useQuery([CM_PADIPATHARKU_DATA, date], () => getCmPadipatharku(date, date));

  if (isLoading) {
    return showLoadingIcon();
  }

  return (
    <div className="CmPadipatharku h-full" data-testid="CmPadipatharku">
      <TopTrending title={Cm_Padipatharku} newsData={data} />
    </div>
  );
};

export default CmPadipatharku;
