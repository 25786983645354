import axios, { AxiosInstance } from "axios";
import { URL } from "../config/URL.Config";
import { store } from "../store/store";
import { setUser } from "../store/user.slice";
import { checkAndUpdateAuthToken } from "../utils/authHelper";
import * as authStorage from "./AuthService";
import { loginRequest, logoutRequest, msalInstance } from "./MsalService";

export default class AxiosServices {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create();
    this.init();
  }

  init() {
    this.registerRequestInterceptor();
    this.registerResponseInterceptor();
  }

  getServiceInstance() {
    return this.axiosInstance;
  }

  registerRequestInterceptor() {
    this.axiosInstance.interceptors.request.use(
      async (config: any) => {
        let authToken: string = authStorage.authToken() ?? "";
        if (config.url.match(process.env.REACT_APP_KK_BASEURL)) {
          authToken = authStorage.kkAuthToken() ?? "";
        } else if (config.url.match(process.env.REACT_APP_CC_BASEURL)) {
          authToken = authStorage.ccAuthToken() ?? "";
        }
        if (authToken && authToken.length > 0) {
          config.headers["Authorization"] = "Bearer " + authToken;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }

  registerResponseInterceptor() {
    this.axiosInstance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      async (error: { config: any; response: { status: number } }) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
          try {
            const silentRequest = {
              scopes: loginRequest.scopes,
              account: msalInstance.getAllAccounts()[0] ?? undefined,
            };
            msalInstance
              .acquireTokenSilent(silentRequest)
              .then(async (authResponse) => {
                if (authResponse) {
                  await checkAndUpdateAuthToken(authResponse.accessToken);
                  authStorage.setAuthDetails(authResponse.accessToken, authResponse.idToken);
                  store.dispatch(setUser(authResponse.account));
                }
              })
              .catch((error) => {
                console.log("error", error);
                logoutRequest();
              });
          } catch (e) {
            logoutRequest();
          }
        }
        return Promise.reject(error);
      }
    );
  }
}

export const getKCalenderAccessToken = async (token: any) => {
  try {
    const res = await fetch(URL.kCalendar.getDaemonAuthToken + `/${token}`);
    if (res.status === 200) {
      const data = await res.json();
      const token = data?.data ?? "";
      return token;
    }
  } catch (err) {
    return "";
  }
  return "";
};

export const refreshKCalenderAccessToken = async (token: any) => {
  try {
    const res = await fetch(URL.kCalendar.refreshDaemonAuthToken + `/${token}`);
    if (res.status === 200) {
      const data = await res.json();
      const token = data?.data ?? "";
      return token;
    }
  } catch (err) {
    return "";
  }
  return "";
};

export const getCmCalenderAccessToken = async (token: any) => {
  try {
    const res = await fetch(URL.cmCalendar.getDaemonAuthToken + `/${token}`);

    if (res.status === 200) {
      const data = await res.json();
      const token = data?.data ?? "";
      return token;
    }
  } catch (err) {
    return "";
  }
  return "";
};

export const refreshCmCalenderAccessToken = async (token: any) => {
  const res = await fetch(URL.cmCalendar.refreshDaemonAuthToken + `/${token}`);
  if (res.status === 200) {
    const data = await res.json();
    const token = data?.data ?? "";
    return token;
  }

  return "";
};
