import React from "react";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";

interface ILayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<ILayoutProps> = (props) => {
  return (
    <>
      <div className="bg-bgImg h-full min-h-screen w-full dark:bg-darkPrimary">
        <div className="">
          <Header />

          <div className="flex sm:flex-col xs:flex-col md:flex-col mdi:flex-col">
            <div className="flex w-15 items-center xs:hidden sm:hidden md:hidden mdi:hidden">
              <Sidebar />
            </div>
            <div className="w-full mb-5">{props.children}</div>
            <div className="xs:flex sm:flex md:flex mdi:flex flex-col hidden fixed bottom-0 w-full">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
