interface IImageIconProps {
  color?: string;
}

export const ImageIcon = (props: IImageIconProps) => {
  const color = props.color ? props.color : "#000000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40">
      <g id="Group_8" data-name="Group 8" transform="translate(-162 -1683)">
        <rect id="Rectangle_30" data-name="Rectangle 30" width="40" height="40" transform="translate(162 1683)" fill="none" />
        <g id="Group_6" data-name="Group 6" transform="translate(8.611 -56.697)">
          <path
            id="Path_5"
            data-name="Path 5"
            d="M4148.027,1659.233l.3,28.927h34.117v-28.927Z"
            transform="translate(-3992 86)"
            fill="none"
            stroke={color}
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_6"
            data-name="Path 6"
            d="M4148.43,1683.577l8.373-8.167,6.875,7.121,10.19-10.323,8.539,8.735"
            transform="translate(-3991.656 84.728)"
            fill="none"
            stroke={color}
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <g id="Ellipse_6" data-name="Ellipse 6" transform="translate(167.728 1750.435)" fill="none" stroke={color} strokeWidth="2">
            <circle cx="3.608" cy="3.608" r="3.608" stroke="none" />
            <circle cx="3.608" cy="3.608" r="2.608" fill="none" />
          </g>
        </g>
      </g>
    </svg>
  );
};
