import React, { FC, useEffect, useState } from "react";
import "./Header.scss";
import moment from "moment";

import { CalendarIcon } from "../../common/icons/CalendarIcon";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/dark.css";
import { CMLeaderBoard, Dark, Light } from "../../utils/constants";
import { LogoutIcon } from "../../common/icons/LogoutIcon";
import { getAppTheme, setAppTheme } from "../../api/AuthService";
import { ThemeIcon } from "../../common/icons/ThemeIcon";
import { isAppDarkTheme } from "../../utils/helper";
import { logoutRequest } from "../../api/MsalService";

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const res = window.sessionStorage.getItem("datePicker");
  const d = res != null && res !== "" ? new Date(res) : new Date(Date.now());

  const [color, setColor] = useState<any>();
  const [initDate] = useState<Date>(d);
  const [userDate, setUserDate] = useState<Date | null>(null);
  const [fp, setFP] = useState<Flatpickr | null>();

  useEffect(() => {
    const color = isAppDarkTheme() ? "white" : "black";
    if (isAppDarkTheme()) document.documentElement.classList.add(Dark);
    else document.documentElement.classList.remove(Dark);
    setColor(color);
  }, []);

  useEffect(() => {
    if (userDate != null) {
      window.sessionStorage.setItem("datePicker", moment(userDate).format("YYYY-MM-DD"));
      window.location.reload();
    }
  }, [userDate]);

  return (
    <header>
      <div className="mx-2 rounded-r-lg card bg-white dark:darkCard py-2">
        <div className="flex items-center justify-between mx-5">
          <div className="text-title font-bold  dark:text-textWhite ml-5">{CMLeaderBoard}</div>

          <div className="flex justify-between items-center">
            <div
              className="cursor-pointer"
              onClick={() => {
                const switchTheme = getAppTheme() === Dark ? Light : Dark;
                setAppTheme(switchTheme);
                window.location.reload();
              }}
            >
              <ThemeIcon color={color} />
            </div>
            <div
              className="cursor-pointer justify-self-end"
              onClick={() => {
                if (fp) {
                  fp.flatpickr.open();
                }
              }}
            >
              <CalendarIcon color={color} />
            </div>
            <Flatpickr
              className="date-input text-subTitle dark:!text-textWhite"
              value={initDate}
              onChange={([date]: any) => setUserDate(date)}
              options={{ dateFormat: "D, d - M - Y" }}
              size={18}
              ref={(fp: any) => {
                setFP(fp);
              }}
            />
            <div onClick={logoutRequest} className="cursor-pointer justify-self-end">
              <LogoutIcon color={color} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
