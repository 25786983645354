export const Constituency = "Constituency";
export const Department = "Department";
export const Party = "Party";
export const Alliances = "Alliances";
export const Opposition = "Opposition";
export const NumOfEvents = "Number Of Events";
export const TotalEvents = "Total Events";
export const Total = "Total";
export const AssemblyConstituency = "Assembly Constituency";

export const Positive = "Positive";
export const Negative = "Negative";
export const Neutral = "Neutral";
export const DMK = "DMK";
export const Government = " Government";
export const CM = "CM";
export const OtherParties = "Other Parties";
export const Sentiment = "Sentiment";
export const PaperCoverage = "Paper Coverage";
export const Cm_Padipatharku = "Cm Padipatharku";

export const CMLeaderBoard = "CM Leaderboard";
export const LastUpdated = "Last Updated On";

export const Dark = "dark";
export const Light = "Light";

export const errorString = "Something Went Wrong";
export const noDataString = "To be updated";
export const printMediaNoDataString = "News to be updated";

export const WordCloudBlob = "wordcloud";
export const ItemsPerPage = "Items Per Page";
export const From = "From:";
export const To = "To:";
export const Dates = "Date";
export const Title = "Title";
export const fileUpload = "FileUpload";
export const fileDownload = " file download";
export const WhatsAppTrending = "WhatsApp Trending";
export const AddNew = "Add New +";
export const Parties = "Parties";
export const UploadFile = "Upload file";
export const SubmitBtn = "Submit";
export const ClearBtn = "Clear";
export const dateAndTime = "date and time";
export const WhatsAppTrendingAddData = "WhatsApp Trending Add Data";
