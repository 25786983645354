import { CalendarMenuIcon } from "../icons/CalendarMenuIcon";
import { ElectionIcon } from "../icons/ElectionIcon";
import { GovernmentIcon } from "../icons/GovernmentIcon";
import { PartyIcon } from "../icons/PartyIcon";
import { MediaIcon } from "../icons/MediaIcon";
import { SocialMediaIcon } from "../icons/SocialMediaIcon";

export const GovernmentRoutePath = "/Government";
export const PartyRoutePath = "/Party";
export const MediaRoutePath = "/Media";
export const SocialMediaRoutePath = "/SocialMedia";
export const ElectionRoutePath = "/Election";
export const CalendarRoutePath = "/Calendar";
export const DataEntryRoutePath  = "/DataEntry"
export const AddDataRoutePath ="/DataEntry/AddData"

export const GovernmentTitle = "Govt";
export const PartyTitle = "Party";
export const MediaTitle = "Media";
export const SocialMediaTitle = "Social";
export const ElectionTitle = "Election";
export const CalendarTitle = "Calendar";

export enum AppMenu {
  Government = 0,
  Party = 1,
  Media = 2,
  SocialMedia = 3,
  Election = 4,
  Calendar = 5,
}

export const AppMenus = [
  // {
  //   icon: AppMenu.Government,
  //   title: GovernmentTitle,
  //   routingPath: GovernmentRoutePath,
  // },
  // {
  //   icon: AppMenu.Party,
  //   title: PartyTitle,
  //   routingPath: PartyRoutePath,
  // },
  {
    icon: AppMenu.SocialMedia,
    title: SocialMediaTitle,
    routingPath: SocialMediaRoutePath,
  },
  {
    icon: AppMenu.Media,
    title: MediaTitle,
    routingPath: MediaRoutePath,
  },
  // {
  //   icon: AppMenu.Election,
  //   title: ElectionTitle,
  //   routingPath: ElectionRoutePath,
  // },
  {
    icon: AppMenu.Calendar,
    title: CalendarTitle,
    routingPath: CalendarRoutePath,
  },
];

export const GetAppMenuIcon = (index: AppMenu, color: string) => {
  switch (index) {
    case AppMenu.Government:
      return <GovernmentIcon color={color} />;
    case AppMenu.Party:
      return <PartyIcon color={color} />;
    case AppMenu.Media:
      return <MediaIcon color={color} />;
    case AppMenu.SocialMedia:
      return <SocialMediaIcon color={color} />;
    case AppMenu.Election:
      return <ElectionIcon color={color} />;
    case AppMenu.Calendar:
      return <CalendarMenuIcon color={color} />;
  }
};
