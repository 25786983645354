type ElectionIconProps = {
  color?: string;
};

export const ElectionIcon = (props: ElectionIconProps) => {
  const color = props.color ? props.color : "#000000";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40">
      <g id="Group_832" data-name="Group 832" transform="translate(11054 17084)">
        <rect id="Rectangle_4410" data-name="Rectangle 4410" width="40" height="40" transform="translate(-11054 -17084)" fill="none" />
        <g id="Group_831" data-name="Group 831" transform="translate(25.755 48)">
          <g id="Group_801" data-name="Group 801" transform="translate(-56.606 -91.705)">
            <path
              id="Path_10764"
              data-name="Path 10764"
              d="M-11134.809-17278.877l-2.58,2.082s-1.45.809-1.149,1.389,2.313.2,3.053-.252a3.016,3.016,0,0,1,2.107-.41c.39.137,1.237.8,1.755.41a20.422,20.422,0,0,0,1.967-2.264c.307-.375,1.619-2.262,1.619-2.262"
              transform="translate(133.055 249.546)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="1"
            />
            <path
              id="Path_10765"
              data-name="Path 10765"
              d="M-11127.9-17283.295"
              transform="translate(132.543 248)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="1"
            />
            <path
              id="Path_10766"
              data-name="Path 10766"
              d="M-11138.093-17278.021s.756-1.762,1.514-2,4.039,1.174,4.683.82a40.381,40.381,0,0,0,3.621-3.99"
              transform="translate(132.728 247.999)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="1"
            />
            <path
              id="Path_10767"
              data-name="Path 10767"
              d="M-11143.508-17274.4l2.076-4.816,4.723,2.059"
              transform="translate(132.938 249.179)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="1"
            />
            <path
              id="Path_10768"
              data-name="Path 10768"
              d="M-11131.932-17274.43l-.189.492"
              transform="translate(132.653 249.437)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="1"
            />
          </g>
          <path
            id="Path_10800"
            data-name="Path 10800"
            d="M-10932.146-17110.436h14.176"
            transform="translate(-136.101 -4)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Path_10801"
            data-name="Path 10801"
            d="M-10930.436-17113.736h-3.647l-3.274,7.275h31.2l-3.945-7.275h-3.455"
            transform="translate(-138 -5)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_10802"
            data-name="Path 10802"
            d="M-10936.148-17104.979v9.7h28.818l-.221-9.7"
            transform="translate(-138 -5)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};
