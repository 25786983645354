import { Colors } from "../components/Charts/Helpers/ChartUtility";
import { isAppDarkTheme } from "./helper";

export interface BarChartOptionsProps {
  yAxisTitle?: any;
  xAxisTitle?: any;
  xTicksFont?: any;
  yTicksFont?: any;
  dataLabels?: any;
  stepSize?: number | null;
  layout?: any;
  indexAxis?: string;
  animation?: any;
  xTicksDisplay?: any;
  dataLabelColor?: any;
}

export const getBarChartOptions = (props: BarChartOptionsProps) => {
  const isDarkTheme = isAppDarkTheme();
  const txtColor = isDarkTheme ? Colors.white : Colors.black;

  return {
    plugins: {
      title: {
        display: false,
      },
      datalabels: props.dataLabels ? props.dataLabels : getDataLabels(props.dataLabelColor),
      legend: {
        display: false,
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          display: props.xTicksDisplay ?? true,
          color: txtColor,
          font: props.xTicksFont,
        },
        grid: {
          display: false,
          color: isDarkTheme ? Colors.darkThemeLightGray : Colors.lightGray,
          drawBorder: false,
        },
        title: props.xAxisTitle,
      },
      y: {
        border: {
          display: false,
        },
        stacked: true,
        ticks: {
          color: txtColor,
          stepSize: props.stepSize ? props.stepSize : 1,
          font: props.yTicksFont,
        },
        grid: {
          display: props.indexAxis !== "y",
          drawBorder: false,
          color: isDarkTheme ? Colors.darkThemeLightGray : Colors.lightGray,
        },
        suggestedMin: 0,
        suggestedMax: 3,
        title: props.yAxisTitle,
      },
    },
    layout: props.layout ? props.layout : {},
    indexAxis: props.indexAxis,
    animations: props.animation ? props.animation : {},
  };
};

const getDataLabels = (labelcolor?: string) => {
  return {
    display: true,
    borderRadius: 4,
    color: labelcolor ? labelcolor : Colors.white,
    font: {
      weight: "bold",
    },
    formatter: (value: number) => {
      return value === 0 ? "" : value;
    },
    anchor: "center",
    align: "top",
    offset: -13,
  };
};

export interface TimeBarChartOptionsProps {
  minTime: string;
  maxTime: string;
  yAxisTitle?: any;
  xAxisTitle?: any;
  yTicksFont?: any;
  dataLabels?: any;
  stepSize?: number | null;
  layout?: any;
  indexAxis?: string;
}

export const getTimeBarChartOptions = (props: TimeBarChartOptionsProps) => {
  return {
    plugins: {
      title: {
        display: false,
      },
      datalabels: props.dataLabels ? props.dataLabels : getDataLabels,
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        type: "time",
        time: {
          parser: "yyyy-MM-dd HH:mm:ss",
          stepSize: props.stepSize ? props.stepSize : 1,
          unit: "minute",
          displayFormats: {
            minute: "hh:mm a",
          },
        },
        ticks: {
          color: Colors.white,
        },

        min: props.minTime,
        max: props.maxTime,
        grid: {
          drawBorder: true,
          color: "",
          borderColor: Colors.lightGray,
        },
        title: props.xAxisTitle,
      },
      y: {
        stacked: true,
        ticks: {
          color: Colors.white,
          font: props.yTicksFont,
        },
        grid: {
          drawBorder: true,
          color: "",
          borderColor: Colors.lightGray,
        },
        title: props.yAxisTitle,
      },
    },
    layout: props.layout ? props.layout : "",
    indexAxis: props.indexAxis,
  };
};
