type ICalendarMenuIconProps = {
  color?: string;
};

export const CalendarMenuIcon = (props: ICalendarMenuIconProps) => {
  const color = props.color ? props.color : "#000000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="10 10 30 30">
      <g id="Group_834" data-name="Group 834" transform="translate(11079 17027)">
        <g id="calender" transform="translate(-11078.75 -17027.15)">
          <path
            id="Path_10803"
            data-name="Path 10803"
            d="M13.15,11.25V7.15M3.75,16.6H7.8M13.15,26V22"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Path_10804"
            data-name="Path 10804"
            d="M20.75,17.5l-.05-3.8m6.5,11.65q-.1,1-1,1a.908.908,0,0,1-.65-.3.779.779,0,0,1-.3-.7.806.806,0,0,1,.3-.65.908.908,0,0,1,.65-.3.779.779,0,0,1,.7.3A.806.806,0,0,1,27.2,25.35Zm-5.45.1A1.025,1.025,0,0,1,20.7,26.5a.806.806,0,0,1-.65-.3.947.947,0,0,1-.35-.75.861.861,0,0,1,.35-.75.806.806,0,0,1,.65-.3,1.018,1.018,0,0,1,.75.3A.92.92,0,0,1,21.75,25.45ZM28.9,15.6l-6.5.05m-3.1.1H17.85l-.45.05a3.246,3.246,0,0,0-.8.5.755.755,0,0,0-.3.25,1.666,1.666,0,0,0-.6,1.35l.15,13.2.4,1.25a2.317,2.317,0,0,0,2.4,1.25l14.2.1a4.309,4.309,0,0,0,1.4-.4,2.226,2.226,0,0,0,1.25-2.4l.05-12.4a3.9,3.9,0,0,0-.3-1.45,2.187,2.187,0,0,0-2.4-1.45h-1m.25,9.7a.938.938,0,0,1-.95.95A1.019,1.019,0,0,1,30.4,26a1.06,1.06,0,0,1-.25-.7.861.861,0,0,1,.3-.7,1.337,1.337,0,0,1,.7-.35.691.691,0,0,1,.65.35A.959.959,0,0,1,32.1,25.3Zm-1.45-8.1-.1-3.6m-8.7,16.05a1.08,1.08,0,0,1-.3.7.832.832,0,0,1-1.3.05l-.05-.05a1.08,1.08,0,0,1-.3-.7,1.234,1.234,0,0,1,.3-.7.946.946,0,0,1,.7-.25.672.672,0,0,1,.6.2l.05.05A1.234,1.234,0,0,1,21.85,29.65ZM32.1,29.6a.893.893,0,0,1-.2.65,1.375,1.375,0,0,1-.7.25,1,1,0,0,1-.65-.25.722.722,0,0,1-.25-.6V29.6a1.375,1.375,0,0,1,.25-.7,1.009,1.009,0,0,1,.65-.2.739.739,0,0,1,.65.2.879.879,0,0,1,.25.65Zm-5,0a.779.779,0,0,1-.3.7,1.008,1.008,0,0,1-.7.35.861.861,0,0,1-.75-.35.959.959,0,0,1-.3-.7,1.132,1.132,0,0,1,.25-.75,1.018,1.018,0,0,1,.75-.3,1.134,1.134,0,0,1,.75.3A.92.92,0,0,1,27.1,29.6ZM17.05,21.7l17.35.1"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_10805"
            data-name="Path 10805"
            d="M14.65,19.75a3.867,3.867,0,0,1-1.4.25,3.361,3.361,0,0,1-2.4-.95,3.441,3.441,0,0,1-1-2.45,3.169,3.169,0,0,1,1-2.4,3.268,3.268,0,0,1,4.8,0q.2.2.45.5"
            fill="none"
            stroke={color}
            strokeWidth="1"
          />
          <path
            id="Path_10806"
            data-name="Path 10806"
            d="M9.3,20.4,7.8,22M18.45,11.25l-1.6,1.55M7.8,11.25,9.3,12.8"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="0.95"
          />
        </g>
        {/* <g id="Group_833" data-name="Group 833" transform="translate(-25 57)">
          <rect id="Rectangle_4410" data-name="Rectangle 4410" width="40" height="40" transform="translate(-11054 -17084)" fill="none" stroke={color} />
        </g> */}
      </g>
    </svg>
  );
};
