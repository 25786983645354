import React, { FC, useEffect, useRef, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import uuid from "react-uuid";
import { VideoIcon } from "../../common/icons/VideoIcon";
import { ITopNewsItem, ITopNewsItems } from "../../models/Common";
import { Negative, Positive, printMediaNoDataString } from "../../utils/constants";
import { isAppDarkTheme, showText } from "../../utils/helper";
import { getDepartmentOptions, getPartyOptions, getSubjectOptions } from "../../utils/mediaHelper";
import { Popup } from "reactjs-popup";
import "./TopTrending.scss";
import PopupContent from "../../utils/popup";
import { ImageIcon } from "../../common/icons/ImageIcon";

interface TopTrendingProps {
  title: string;
  hasSentiment?: boolean;
  hasFilter?: boolean;
  newsData?: ITopNewsItems;
  positiveOnClick?: any;
  negativeOnClick?: any;
  hasWhatsappFilter?: boolean;
  departmentChanged?: any;
  subjectChanged?: any;
  partyChanged?: any;
}

const TopTrending: FC<TopTrendingProps> = (props: TopTrendingProps) => {
  const [activeId, setActiveId] = useState<string>("positiveBtn");
  const [departmentOptions, setDepartmentOptions] = useState<any>([]);
  const [selectedDepartmentOptions, setSelectedDepartmentOptions] = useState<any>([]);
  const [partyOptions, setPartyOptions] = useState<any>([]);
  const [selectedPartyOptions, setSelectedPartyOptions] = useState<any>([]);

  const [subjectOptions, setSubjectOptions] = useState<any>([]);
  const [selectedSubjectOptions, setSelectedSubjectOptions] = useState<any>([]);
  const [departmentsDeselected, setDepartmentsDeselected] = useState(false);
  const [subjectsDeselected, setSubjectsDeselected] = useState(false);
  const [partiesDeselected, setPartiesDeselected] = useState(false);
  const popupRef = useRef<any>();

  useEffect(() => {
    if (props?.hasFilter) {
      const departOptions = getDepartmentOptions();
      setDepartmentOptions(departOptions);
      if (selectedDepartmentOptions.length === 0 && !departmentsDeselected) setSelectedDepartmentOptions(departOptions);

      const subOptions = getSubjectOptions();
      setSubjectOptions(subOptions);
      if (selectedSubjectOptions.length === 0 && !subjectsDeselected) setSelectedSubjectOptions(subOptions);
    }
    if (props?.hasWhatsappFilter) {
      const options = getPartyOptions();
      setPartyOptions(options);
      if (selectedPartyOptions.length === 0 && !partiesDeselected) setSelectedPartyOptions(options);
    }
  }, [props.newsData]);

  const positiveOnClick = (event: any) => {
    setActiveId(event.target.id);
    props.positiveOnClick();
  };
  const negativeOnClick = (event: any) => {
    setActiveId(event.target.id);
    props.negativeOnClick();
  };

  const subjectChanged = (data: any) => {
    if (data.length === 0) setSubjectsDeselected(true);
    else if (subjectsDeselected) setSubjectsDeselected(false);
    setSelectedSubjectOptions(data);
    props.subjectChanged(data);
  };

  const departmentChanged = (data: any) => {
    if (data.length === 0) setDepartmentsDeselected(true);
    else if (departmentsDeselected) setDepartmentsDeselected(false);
    setSelectedDepartmentOptions(data);
    props.departmentChanged(data);
  };

  const partyChanged = (data: any) => {
    if (data.length === 0) setPartiesDeselected(true);
    else if (partiesDeselected) setPartiesDeselected(false);
    setSelectedPartyOptions(data);
    props.partyChanged(data);
  };

  const closeModal = () => {
    if (popupRef.current) popupRef.current.close();
  };

  const showMediaIcon = (news: any) => {
    const color = isAppDarkTheme() ? "white" : "black";
    return (
      <div className="truncate text-[#5154D3] text-center mr-5 flex justify-center">
        {news.fileExtension === "video" ? <VideoIcon color={color} /> : <ImageIcon color={color} />}
      </div>
    );
  };

  const showParty = (news: any) => {
    return (
      <div className="truncate  text-[#5154D3] bg-[#F4F4FC] text-center mr-5 h-fit self-center" title={`${news.party}`}>
        {news.party}
      </div>
    );
  };

  const showDepartment = (news: any) => {
    return (
      <div className="truncate text-[#5154D3] bg-[#F4F4FC] text-center mr-5 h-fit self-center" title={`${news.department}`}>
        {news.department}
      </div>
    );
  };

  const showSubject = (news: any) => {
    return (
      <div className="truncate  text-[#5D5C56] bg-[#FFF8DE]  text-center h-fit self-center" title={`${news.subject}`}>
        {news.subject}
      </div>
    );
  };

  return (
    <div className="topTrending h-full overflow-hidden " data-testid="TopTrending">
      <div className="flex">
        <div className="text-cardTitle dark:text-textWhite font-bold ml-5 mt-2 w-full">{props.title}</div>
        {props.hasSentiment && (
          <div className="flex w-full justify-end">
            <div className="mt-2 border-[#867F7F80] border-[1px] rounded-lg mr-5">
              <button
                id="positiveBtn"
                className={`hover:bg-[#54A23C] hover:text-textWhite dark:text-textWhite px-5 py-1 rounded-l-md ${
                  activeId === "positiveBtn" ? "positiveBtnActive" : ""
                }`}
                onClick={positiveOnClick}
              >
                {Positive}
              </button>
              <button
                id="negativeBtn"
                className={`hover:bg-[#D33E4D] hover:text-textWhite dark:text-textWhite px-5 py-1 rounded-r-md ${
                  activeId === "negativeBtn" ? "negativeBtnActive" : ""
                }`}
                onClick={negativeOnClick}
              >
                {Negative}
              </button>
            </div>
          </div>
        )}
      </div>

      {props.hasFilter && (
        <div className="flex mt-2 justify-between w-full h-fit">
          <div className="w-[70%] inline-flex"></div>
          <div className="inline-flex">
            <div className="w-full">
              <MultiSelect
                options={departmentOptions}
                value={selectedDepartmentOptions}
                onChange={(data: any) => departmentChanged(data)}
                labelledBy="Select"
                ClearSelectedIcon={<></>}
                overrideStrings={{
                  allItemsAreSelected: "Departments",
                  selectAll: "All Departments",
                }}
              />
            </div>
            <div className="w-full">
              <MultiSelect
                options={subjectOptions}
                value={selectedSubjectOptions}
                onChange={(data: any) => subjectChanged(data)}
                labelledBy="Select"
                ClearSelectedIcon={<></>}
                overrideStrings={{
                  allItemsAreSelected: "Subjects",
                  selectAll: "All Subjects",
                }}
              />
            </div>
          </div>
        </div>
      )}

      {props.hasWhatsappFilter && (
        <div className="flex mt-[-1rem] justify-between w-full">
          <div className="w-[80%] inline-flex"></div>
          <div className="inline-flex">
            <div className="w-full">
              <MultiSelect
                options={partyOptions}
                value={selectedPartyOptions}
                onChange={(data: any) => partyChanged(data)}
                labelledBy="Select"
                ClearSelectedIcon={<></>}
                overrideStrings={{
                  allItemsAreSelected: "Parties",
                  selectAll: "All Parties",
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div className={`mt-1 h-[90%] overflow-y-scroll`}>
        {props.newsData && props.newsData.length ? (
          <ul className={`ml-2 mb-14`}>
            {props.newsData?.map((news: ITopNewsItem) => (
              <li key={uuid()} className="leading-relaxed tracking-wide p-3 border-b-[1px] border-[#DED4D4] w-full">
                <div className="grid grid-cols-12 gap-x-5">
                  <div className={`col-span-12 ${news.filePath && "!col-span-10"} ${props.hasFilter && "!col-span-8"} dark:text-textWhite mr-1.5`}>
                    <div>
                      {news.filePath ? (
                        <div className="w-full active:bg-gray-300 hover:text-[#5154D3] hover:cursor-pointer">
                          <Popup
                            ref={popupRef}
                            closeOnDocumentClick
                            onClose={closeModal}
                            trigger={
                              <div className="flex w-full items-center">
                                <div className="w-[90%]">{news.title}</div>
                                {showMediaIcon(news)}
                              </div>
                            }
                            modal
                            nested
                            position={"top left"}
                          >
                            <div className="modal">
                              <PopupContent title={news.title} url={news.filePath} fileExtension={news.fileExtension} onClose={closeModal} />
                            </div>
                          </Popup>
                        </div>
                      ) : (
                        <div> {news.title}</div>
                      )}
                    </div>
                  </div>
                  {news.party && <span className="col-span-2">{showParty(news)}</span>}
                  {news.department && <span className="col-span-2">{showDepartment(news)}</span>}
                  {news.subject && <span className="col-span-2">{showSubject(news)}</span>}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          showText(printMediaNoDataString)
        )}
      </div>
    </div>
  );
};

export default TopTrending;
