import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import "./Map.scss";
import "leaflet/dist/leaflet.css";
import { MapContainer, GeoJSON, Marker, Popup } from "react-leaflet";
import { Layer, divIcon } from "leaflet";
import { getEventType, getMarkerColor, getMarkerIcon, isAppDarkTheme } from "../../utils/helper";
import uuid from "react-uuid";
import { Color } from "../../enums/color";

interface MapProps {
  className?: any;
  onClick?: any;
  geoData?: any;
  markerData?: any;
  markerColor?: string;
  pathOptions: any;
  height?: string;
  selectedFeature?: any;
  currentBounds?: any;
  availableFeatures?: any;
  resetZoom?: boolean;
  zoomControl?: boolean;
  dragging?: boolean;
  scrollWheelZoom?: boolean;
}

const LeafletMap: FC<MapProps> = (props: MapProps) => {
  const map = useRef<any>();
  const geo = useRef<any>();
  const [readyMap, setMap] = useState<any>("");
  const [geoJsonData, setGeoJson] = useState(props.geoData);
  const [markerData, setMarkerData] = useState(props.markerData);
  const [currentBounds, setCurrentBounds] = useState(props.currentBounds);
  const [curentMapBounds, setCurentMapBounds] = useState<any>(null);

  useEffect(() => {
    setGeoJson(props.geoData);
    setMarkerData(props.markerData);
    setCurrentBounds(props.currentBounds);
  }, [props.geoData, props.markerData, props.currentBounds]);

  useEffect(() => {
    if (map.current && currentBounds) {
      map.current.fitBounds(currentBounds, { padding: [5, 10] });
    }
  }, [currentBounds, props.height, props.resetZoom]);

  useEffect(() => {
    if (map.current && geo.current) {
      map.current.fitBounds(geo.current.getBounds(), { padding: [5, 10] });
    }
  }, [readyMap, map, geo, geoJsonData, markerData, props.height, props.resetZoom]);

  useEffect(() => {
    if (geo.current) {
      geo.current.clearLayers(); // remove old data
      geo.current.addData(props.geoData); // might need to be geojson.features
      geo.current.setStyle(props.pathOptions);
      setCurentMapBounds(geo.current.getBounds());
    }
  }, [geoJsonData, markerData]);

  // sometimes map bounds is not fitting properly, below useEffect is used to fit the map bounds.
  useEffect(() => {
    if (geo.current && map.current && curentMapBounds) {
      map.current.fitBounds(curentMapBounds, { padding: [5, 10] });
    }
  }, [curentMapBounds]);

  const setStyle = (feature: any) => {
    const isDarkTheme = isAppDarkTheme();
    if (props.selectedFeature) {
      if (props?.selectedFeature?.feature.properties.CONSTITUENCY === feature.properties.CONSTITUENCY) {
        return {
          ...props.pathOptions,
          fillColor: isDarkTheme ? Color.darkHighlightGrey : Color.highlightGrey,
          fillOpacity: 1,
        };
      }
    }

    if (props.availableFeatures?.includes(feature.properties.CONSTITUENCY)) {
      return { ...props.pathOptions, fillOpacity: 1, opacity: 1 };
    }

    if (props.availableFeatures) {
      return { ...props.pathOptions, fillOpacity: 0.3, opacity: isDarkTheme ? 0.8 : 0.4 };
    }

    return props.pathOptions;
  };

  const onEachFeatureCallback = useCallback((feature: any, layer: Layer) => onEachFeature(feature, layer), []);
  const whenReadyCallback = useCallback(() => setMap(map), []);

  function onEachFeature(feature: any, layer: Layer) {
    if (layer) {
      const constituency = feature.properties.CONSTITUENCY;
      layer.bindTooltip(constituency);

      layer.on({
        click: function (e: any) {
          props.onClick(e);
        },
      });
    }
  }

  const getMapPinIcon = (eventType?: string) => {
    const color = getMarkerColor(getEventType(eventType));
    return divIcon({
      className: "box",
      html: getMarkerIcon(color),
    });
  };

  const MultiMarker = () => {
    const data = markerData ?? [];
    return data.map((item: any) => {
      return (
        <Marker key={uuid()} position={item.location} icon={getMapPinIcon(item.eventType)}>
          {item.event && <Popup> {item.event}</Popup>}
        </Marker>
      );
    });
  };

  return (
    <div className="Map h-full" data-testid="Map">
      <MapContainer
        className={`${props.className || "!bg-white dark:!bg-darkSecondary mb-2"} h-full`}
        ref={map}
        whenReady={whenReadyCallback}
        center={[0, 0]}
        zoom={0}
        maxZoom={50}
        zoomSnap={0}
        doubleClickZoom={false}
        style={{ height: props.height || "100%" }}
        attributionControl={false}
        zoomControl={props.zoomControl || false}
        dragging={props.dragging || false}
        scrollWheelZoom={props.scrollWheelZoom || false}
      >
        {geoJsonData && <GeoJSON data={geoJsonData} ref={geo} style={setStyle} onEachFeature={onEachFeatureCallback} />}

        {markerData && MultiMarker()}
      </MapContainer>
    </div>
  );
};

export default LeafletMap;
