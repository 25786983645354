import { URL } from "../config/URL.Config";
import { INewsArticlesItems, ISubjectSplitItem } from "../models/PrintMedia";
import { ITopNewsItems, ISentimentItem } from "../models/Common";
import AxiosServices from "./AxiosServices";

const instance = new AxiosServices().getServiceInstance();

export const getPrintMediaTopNews = async (count: number, date: string, sentiment?: string, departments?: string[], subjects?: string[]) => {
  let topNews: ITopNewsItems;

  let url = `${URL.media.topNews}/${count}/${date}?sentiment=${sentiment}`;
  const departmentValid = departments && departments.length > 0;
  const subjectValid = subjects && subjects.length > 0;

  if (departmentValid) {
    const departmentQuery = encodeURIComponent(departments.join(","));
    url = `${url}&department=${departmentQuery}`;
  }
  if (subjectValid) {
    const subjectQuery = encodeURIComponent(subjects.join(","));
    url = `${url}&subject=${subjectQuery}`;
  }

  const res = await instance.get(url);
  topNews = res.data;
  return topNews;
};

export const getSentimentNarration = async (subject: string, date: string) => {
  let sentimentData: ISentimentItem;
  const res = await instance.get(`${URL.media.sentimentNarration}/${subject}/${date}`);
  sentimentData = Object.keys(res.data).length > 0 ? res.data : null;
  return sentimentData;
};

export const getSubjectSplit = async (date: string) => {
  let subjectSplitData: ISubjectSplitItem;
  const res = await instance.get(`${URL.media.subjectSplit}/${date}`);
  subjectSplitData = Object.keys(res.data).length > 0 ? res.data : null;
  return subjectSplitData;
};

export const getNewsArticles = async (subjects: string[], date: string) => {
  let newsArticlesData: INewsArticlesItems;
  const res = await instance.get(`${URL.media.newsArticles}/${date}?subjects=${subjects}`);
  newsArticlesData = Object.keys(res.data).length > 0 ? res.data : null;
  return newsArticlesData;
};

export const getPaperCoverage = async (date: string, subjects?: string[]) => {
  let paperCoverageData: any;
  let url = `${URL.media.paperCoverage}/${date}`;

  const subjectsValid = subjects && subjects.length > 0;
  if (subjectsValid) url += `?subjects=${subjects}`;

  const res = await instance.get(url);
  paperCoverageData = Object.keys(res.data.data).length > 0 ? res.data : null;
  return paperCoverageData;
};

export const getCmPadipatharku = async (from: string, to: string) => {
  let topNews: ITopNewsItems;
  const res = await instance.get(`${URL.newstuck.getLeaderboardFeed}/${from}/${to}?tenantId=${process.env.REACT_APP_NEWSTUCK_TENANT_ID}`);
  topNews = res.data ? res.data[0].consumerFeedViews : null;
  return topNews;
};
