import React, { FC, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import { AppMenus, GetAppMenuIcon } from "../../common/helper/AppMenu.Config";
import uuid from "react-uuid";
interface ISidebarProps {}

const Sidebar: FC<ISidebarProps> = (props: ISidebarProps) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(getActiveIndex());

  function getActiveIndex() {
    if (location.pathname === "/") return 0;
    return AppMenus.findIndex((x) => x.routingPath.toLowerCase() === location.pathname.toLowerCase()) ?? 0;
  }

  const CheckPath = (index: number) => {
    return location.pathname.toLowerCase() === AppMenus[index].routingPath.toLowerCase() || (location.pathname === "/" && index === 0);
  };

  const getColor = (index: any) => {
    return activeIndex === index ? "white" : "#5154D3";
  };

  return (
    <div className="rounded-r-lg bg-white w-full dark:bg-darkSecondary border-y-[1px] border-r-[1px] border-[#ccc6c644]">
      <div className="w-full">
        <div className="duration-500 sidebar cursor-pointer xs:flex sm:flex md:flex mdi:flex justify-evenly">
          {AppMenus.map((menu, index) => (
            <div
              key={uuid()}
              className="p-2 py-5 sm:py-0 xs:py-0 mdi:py-0 sm:pt-2 xs:pt-2 mdi:pt-2 rounded-r-lg xs:rounded-lg sm:rounded-lg mdi:rounded-lg"
              id={CheckPath(index) ? "active" : ""}
              onClick={() => {
                navigation(menu.routingPath);
                setActiveIndex(index);
              }}
            >
              <Link key={uuid()} to={menu.routingPath} className="">
                <div className="flex justify-center">{GetAppMenuIcon(menu.icon, getColor(index))}</div>
                <div className="flex justify-center xs:hidden sm:hidden md:hidden mdi:hidden dark:text-textWhite">{menu.title}</div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
