import { FC } from "react";
import CmPadipatharku from "../../components/CmPadipatharku/CmPadipatharku";
import MediaSentiment from "../../components/MediaSentiment/MediaSentiment";
import Top5News from "../../components/Top5News/Top5News";
import WordCloud from "../../components/WordCloud/WordCloud";
import "./MediaPage.scss";

interface MediaPageProps {}

const MediaPage: FC<MediaPageProps> = (props: MediaPageProps) => {
  return (
    <>
      <div className="flex flex-col mt-5">
        <div className="grid sm:grid-cols-0 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
          <div className="card dark:darkCard ml-5 mr-5 min-h-[43vh] max-h-[44vh]">
            <Top5News />
          </div>
          <div className="card dark:darkCard mr-5 ml-5 mt-5 2md:ml-0 2md:mt-0 lg:ml-0 lg:mt-0 pt-50 md:mt-5 md:ml-5 sm:mt-5 sm:ml-5 min-h-[43vh] max-h-[44vh]">
            <WordCloud title={"Word Cloud"} />
          </div>
        </div>

        <div className="grid mt-5 sm:grid-cols-0 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
          <div className="card dark:darkCard ml-5 mr-5 min-h-[43vh] max-h-[44vh]">
            <CmPadipatharku />
          </div>
          <div className="card dark:darkCard ml-5 mt-5 mr-5 2md:ml-0 2md:mt-0 lg:ml-0 lg:mt-0 md:mt-5 md:ml-5 sm:mt-5 sm:ml-5 min-h-[43vh] max-h-[44vh] sm:max-h-max xs:max-h-max sm:mb-[5vh] xs:mb-[5vh] mdi:mb-[5vh]">
            <MediaSentiment />
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaPage;
