import { getBlobClient } from "../api/BlobServices";

export async function uploadFile({
  filePath,
  file,
  metaData,
  progressCallback,
}: FileUploadOptions) {
  const blockBlobClient = await getBlobClient(filePath);
  if (blockBlobClient)
    return await blockBlobClient.uploadData(file, {
      ...(metaData && { metadata: metaData }),
      onProgress: (progressEvent_1: { loadedBytes: number }) => {
        // progressCallback(
        //   Math.floor((progressEvent_1.loadedBytes / (file as any).size) * 100)
        // );
      },
    });
}

interface FileUploadOptions {
  filePath: string;
  file: Blob | ArrayBuffer | ArrayBufferView;
  metaData?: { data_date: string };
  progressCallback?: any;
}
