import moment from "moment";

export const getUserSelectedDate = () => {
  const date = window.sessionStorage.getItem("datePicker");

  if (date != null && date !== "") {
    const userDate = new Date(date);
    const currentDate = new Date();
    userDate.setHours(currentDate.getHours());
    userDate.setMinutes(currentDate.getMinutes());
    return userDate;
  }

  return new Date(Date.now());
};

export const getValidDate = (date?: Date, formatStr?: string) => {
  const currentDate = new Date();
  const validDate = date ? new Date(date.getTime()) : currentDate;

  const isSameDay = moment(validDate).startOf("day").diff(moment(currentDate).startOf("day"), "days") === 0;

  if (isSameDay && validDate.getHours() < 11) {
    validDate.setDate(validDate.getDate() - 1);
  }
  return moment(validDate).format(formatStr ?? "YYYY-MM-DD HH:mm:ss");
};

export const calcToDateForTweet = (date?: Date) => {
  const currentDate = date ? new Date(date) : new Date();
  let hrs = currentDate.getHours();
  currentDate.setSeconds(0);
  currentDate.setMinutes(0);

  for (let i = 6; i > 0; i--) {
    if (hrs % 6 === 0) break;
    hrs--;
  }

  if (hrs >= 0 && hrs <= 6) {
    currentDate.setDate(currentDate.getDate() - 1);
    currentDate.setHours(24);
  } else {
    currentDate.setHours(hrs);
  }

  return currentDate;
};

type convertToUtcParams = {
  date: Date;
  strFormat?: string;
  setZeroSec?: boolean;
};

export const convertToUTC = ({ date, strFormat, setZeroSec }: convertToUtcParams) => {
  if (setZeroSec) date.setSeconds(0);

  const utcTime = moment(date).utc();

  return moment(utcTime).format(strFormat || "YYYY-MM-DD HH:mm:ss");
};
