import { FC } from "react";
import Top5Tweets from "../../components/Top5Tweets/Top5Tweets";
import TwitterSentiment from "../../components/TwitterSentiment/TwitterSentiment";
import WhatsAppTop from "../../components/WhatsAppTop/WhatsAppTop";
import WordCloud from "../../components/WordCloud/WordCloud";
import "./SocialMediaPage.scss";

interface SocialMediaPageProps {}

const SocialMediaPage: FC<SocialMediaPageProps> = (props: SocialMediaPageProps) => {
  return (
    <>
      <div className="flex flex-col mt-5 h-full">
        <div className="grid sm:grid-cols-0 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
          <div className="card dark:darkCard ml-5 mr-5 min-h-[43vh] max-h-[44vh]">
            <WhatsAppTop />
          </div>
          <div className="card dark:darkCard mr-5 ml-5 2md:ml-0 2md:mt-0 mt-5 lg:ml-0 lg:mt-0 pt-50 md:mt-5 md:ml-5 sm:mt-5 sm:ml-5 min-h-[43vh] max-h-[44vh]">
            <TwitterSentiment title="Sentiment" />
          </div>
        </div>

        <div className="grid mt-5 sm:grid-cols-0 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
          <div className="card dark:darkCard ml-5 mr-5 min-h-[43vh] max-h-[44vh]">
            <Top5Tweets />
          </div>
          <div className="card dark:darkCard overflow-hidden ml-5 mt-5 mr-5 lg:ml-0 lg:mt-0 md:mt-5 md:ml-5 2md:ml-0 2md:mt-0 sm:mt-5 sm:ml-5 min-h-[43vh] max-h-[44vh]  sm:mb-[5vh] xs:mb-[5vh] mdi:mb-[5vh]">
            <WordCloud title="Word Cloud" isTwitterWordCloud={true} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaPage;
