export const getPartyOptions = (data: any) => {
  const result: any = [];
  if (!data) return result;
  const depart: any = {};

  data.forEach((item: any) => {
    if (result.length <= 0 || !depart[item.party]) {
      const option: any = {
        label: item.party,
        value: item.party,
      };
      depart[item.party] = option;
      result.push(option);
    }
  });

  return result;
};
