interface ICalendarIconProps {
  color?: string;
}

export const CalendarIcon = (props: ICalendarIconProps) => {
  const color = props.color ? props.color : "#000000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g id="Group_828" data-name="Group 828" transform="translate(11152 16971)">
        <rect id="Rectangle_4411" data-name="Rectangle 4411" width="40" height="40" transform="translate(-11152 -16971)" fill="none" />
        <g id="calender" transform="translate(-11146.66 -16965.225)">
          <g id="Group_792" data-name="Group 792">
            <path
              id="Path_10770"
              data-name="Path 10770"
              d="M8.95,17.65A1.538,1.538,0,1,0,7.9,17.2a1.439,1.439,0,0,0,1.05.45M9,15.05a1.273,1.273,0,0,1,.85.35,1.157,1.157,0,0,1,.35.85,1.273,1.273,0,0,1-.35.85,1.393,1.393,0,0,1-.85.3,1.194,1.194,0,0,1-.8-.3,1.273,1.273,0,0,1-.35-.85,1.157,1.157,0,0,1,.35-.85,1.1,1.1,0,0,1,.8-.35m.85.35A1.273,1.273,0,0,0,9,15.05a1.1,1.1,0,0,0-.8.35,1.157,1.157,0,0,0-.35.85,1.273,1.273,0,0,0,.35.85,1.194,1.194,0,0,0,.8.3,1.393,1.393,0,0,0,.85-.3,1.273,1.273,0,0,0,.35-.85A1.157,1.157,0,0,0,9.85,15.4Z"
              fill={color}
            />
          </g>
          <path
            id="Path_10771"
            data-name="Path 10771"
            d="M7.35,5.3H5.7a4.581,4.581,0,0,0-.475.075,2.522,2.522,0,0,0-1.3.8,2.341,2.341,0,0,0-.65,1.55q.013.805.025.975Q3.261,9.727,3.275,12q.029,3.7.175,10.7l.45,1.4a2.9,2.9,0,0,0,2.75,1.4l16.3.1a4.075,4.075,0,0,0,1.55-.45A2.921,2.921,0,0,0,26,22.4l.05-14a4.321,4.321,0,0,0-.35-1.65A2.813,2.813,0,0,0,22.95,5.1h-1.2m-1.3,1.85-.15-4.1M22.05,16.1a1.1,1.1,0,0,1-.35.8,1.018,1.018,0,0,1-.75.3,1.194,1.194,0,0,1-.8-.3,1.15,1.15,0,0,1,.8-1.95.947.947,0,0,1,.75.35A1.1,1.1,0,0,1,22.05,16.1Zm.05,4.85a1.134,1.134,0,0,1-.3.75,1.018,1.018,0,0,1-.75.3A1.025,1.025,0,0,1,20,20.95a1.018,1.018,0,0,1,.3-.75,1.134,1.134,0,0,1,.75-.3,1.025,1.025,0,0,1,1.05,1.05ZM18.4,5.1q-4.912.015-7.4.05M9.1,7.25,9,2.95m1.2,13.3a1.273,1.273,0,0,1-.35.85,1.393,1.393,0,0,1-.85.3,1.194,1.194,0,0,1-.8-.3,1.273,1.273,0,0,1-.35-.85,1.157,1.157,0,0,1,.35-.85,1.1,1.1,0,0,1,.8-.35,1.273,1.273,0,0,1,.85.35A1.157,1.157,0,0,1,10.2,16.25ZM10.3,21a1.194,1.194,0,0,1-.3.8,1.089,1.089,0,0,1-1.6,0,1.194,1.194,0,0,1-.3-.8,1.018,1.018,0,0,1,.3-.75,1.089,1.089,0,0,1,1.6,0A1.018,1.018,0,0,1,10.3,21Zm6.15-4.85A1.2,1.2,0,0,1,15.3,17.3a.947.947,0,0,1-.75-.35,1.1,1.1,0,0,1-.35-.8.861.861,0,0,1,.35-.75.947.947,0,0,1,.75-.35,1.1,1.1,0,0,1,.8.35A.861.861,0,0,1,16.45,16.15Zm-.1,4.8a1.042,1.042,0,0,1-.4.8,1.042,1.042,0,0,1-.8.4,1.089,1.089,0,0,1-.85-.4,1.1,1.1,0,0,1-.35-.8,1.2,1.2,0,0,1,1.2-1.2,1.1,1.1,0,0,1,.8.35A1.089,1.089,0,0,1,16.35,20.95ZM4.8,12l19.9.1"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};
