type PartyIconProps = {
  color?: string;
};

export const PartyIcon = (props: PartyIconProps) => {
  const color = props.color ? props.color : "#000000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40">
      <g id="Group_795" data-name="Group 795" transform="translate(11132 17477)">
        <rect id="Rectangle_4407" data-name="Rectangle 4407" width="40" height="40" transform="translate(-11132 -17477)" fill="none" />
        <g id="Group_794" data-name="Group 794" transform="translate(-10887.125 -20317.125)">
          <g id="Ellipse_50" data-name="Ellipse 50" transform="translate(-230.237 2854.593)" fill="none" stroke={color} strokeWidth="1">
            <circle cx="5.597" cy="5.597" r="5.597" stroke={color} />
            <circle cx="5.597" cy="5.597" r="5.097" fill="none" />
          </g>
          <path
            id="Path_10729"
            data-name="Path 10729"
            d="M0,5.769V0"
            transform="translate(-224.737 2846.828)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Path_10730"
            data-name="Path 10730"
            d="M0,5.642V0"
            transform="translate(-224.737 2867.783)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Path_10731"
            data-name="Path 10731"
            d="M0,5.769V0"
            transform="translate(-211.513 2860.19) rotate(90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Path_10732"
            data-name="Path 10732"
            d="M0,5.769V0"
            transform="translate(-232.468 2860.19) rotate(90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Path_10733"
            data-name="Path 10733"
            d="M0,3.145V0"
            transform="translate(-217.283 2867.783) rotate(135)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Path_10734"
            data-name="Path 10734"
            d="M0,3.145V0"
            transform="translate(-230.244 2854.821) rotate(135)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Path_10735"
            data-name="Path 10735"
            d="M0,3.145V0"
            transform="translate(-232.468 2867.783) rotate(-135)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Path_10736"
            data-name="Path 10736"
            d="M0,3.145.017,0"
            transform="translate(-219.495 2854.833) rotate(-135)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};
