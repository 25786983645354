export const TOTAL_EVENT_COUNT_DATA = "TotalEventCount";
export const DEPARTMENT_NAME_DATA = "DepartmentName";
export const ALLIANCES_NAME_DATA = "AlliancesName";
export const OPPOSITION_NAME_DATA = "OppositionName";
export const ALL_EVENT_LOCATIONS_DATA = "AllEventLocations";
export const LOCATION_DATA = "Location";
export const EVENT_AVAILABLE_CONS_DATA = "EventAvailableCons";
export const TOP_5_TWEETS_DATA = "Top5Tweets";
export const WHATS_APP_TOP_DATA = "WhatsAppTopNews";
export const WHATS_APP_ALL_DATA = "WhatsAppAllData";
export const WHATS_APP_DELETE_DATA = "WhatsappDeleteData";

export const TWEETS_SENTIMENT_DATA = "TweetsSentiment";
export const PRINT_TOP_NEWS = "PrintTopNews";
export const NEWS_ARTICLES_DATA = "NewsArticles";
export const PAPER_COVERAGE_DATA = "PaperCoverage";
export const TWEETS_WORD_CLOUD_DATA = "TwitterWordCloud";
export const PRINT_WORD_CLOUD_DATA = "PrintWordCloud";
export const CM_PADIPATHARKU_DATA = "CmPadipatharku";
export const SAS_TOKEN_DATA = "SasToken";
