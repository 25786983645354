import { URL } from "../config/URL.Config";
import AxiosServices from "./AxiosServices";

const instance = new AxiosServices().getServiceInstance();

export const getPartyLocations = async (fromDate: string, toDate: string, partyType: string, constituency?: string, partyName?: string) => {
  try {
    let url = `${URL.kCalendar.getPartyEventLocations}/${fromDate}/${toDate}/${partyType}`;

    const partyNameValid = partyName && partyName.length > 0;
    const constituencyValid = constituency && constituency.length > 0;

    if (partyNameValid) {
      const departmentQuery = encodeURIComponent(partyName);
      url = `${url}/?partyName=${departmentQuery}`;
    }

    if (constituencyValid) {
      const constituencyQuery = encodeURIComponent(constituency);
      url = partyNameValid ? `${url}/&constituency=${constituencyQuery}` : `${url}/?constituency=${constituencyQuery}`;
    }

    const res = await instance.get(url);
    return res.data.data ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getKKTotalEvents = async (fromDate: string, toDate: string, constituency?: string) => {
  try {
    const url = `${URL.kCalendar.getTotalEventCounts}/${fromDate}/${toDate}`;
    const endPoint = constituency && constituency.length > 0 ? `${url}/?constituency=${constituency}` : url;
    const res = await instance.get(endPoint);
    return res.data.data ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCCTotalEvents = async (fromDate: string, toDate: string, constituency?: string) => {
  try {
    const url = `${URL.cmCalendar.getTotalEventCounts}/${fromDate}/${toDate}`;
    const endPoint = constituency && constituency.length > 0 ? `${url}/?constituency=${constituency}` : url;
    const res = await instance.get(endPoint);
    return res.data.data ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getTotalEventCountsByConstituency = async (fromDate: string, toDate: string) => {
  try {
    const res = await instance.get(`${URL.kCalendar.getTotalEventCounts}/${fromDate}/${toDate}`);
    return res.data.data ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getAllEventLocations = async (fromDate: string, toDate: string) => {
  try {
    const res = await instance.get(`${URL.kCalendar.getAllEventLocations}/${fromDate}/${toDate}`);
    return res.data.data ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getEventAvailableConstituency = async (fromDate: string, toDate: string) => {
  try {
    const res = await instance.get(`${URL.kCalendar.getEventAvailableConstituency}/${fromDate}/${toDate}`);
    const constituencyList = res.data.data.map((item: any) => item.constituency);
    return constituencyList ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};
