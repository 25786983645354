const kkTokenKey = "daemon_access_token_kk";
const ccTokenKey = "daemon_access_token_cc";
const langKey = "appLang";
const themeKey = "appTheme";

export function kkAuthToken() {
  return window.localStorage.getItem(kkTokenKey);
}

export function ccAuthToken() {
  return window.localStorage.getItem(ccTokenKey);
}

export function setKKAuthDetails(accessToken) {
  window.localStorage.setItem(kkTokenKey, accessToken);
}

export function setCCAuthDetails(accessToken) {
  window.localStorage.setItem(ccTokenKey, accessToken);
}

export function authToken() {
  return window.localStorage.getItem("access_token");
}

export function idToken() {
  return window.localStorage.getItem("id_token");
}

export function setAuthDetails(accessToken, idTokenDetails) {
  window.localStorage.setItem("access_token", accessToken);
  window.localStorage.setItem("id_token", idTokenDetails);
}

export function setLang(lang) {
  window.localStorage.setItem(langKey, lang);
}

export function getLang() {
  return window.localStorage.getItem(langKey);
}

export function setAppTheme(theme) {
  window.localStorage.setItem(themeKey, theme);
}

export function getAppTheme() {
  return window.localStorage.getItem(themeKey);
}
