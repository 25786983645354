import moment from "moment";
import { addOrUpdate } from "../api/WhatsappServices";
import { uploadFile } from "./az.blob.service";

export async function uploadFiles(event: any) {
  Array.from(event.fileName).forEach((file: any) => {
    const date = moment().format();
    const filePath = `${date}_${file.name}`;
    const metaData = {
      data_date: date,
    };

    uploadFile({
      filePath,
      file,
      metaData,
    })
      .then(() => {
        // do api call to add in db
        addOrUpdate({
          date: event.DateandTime,
          filePath: `${process.env.REACT_APP_BLOB_CONTAINER_NAME}/${filePath}`,
          title: event.Title,
          party: event.Party,
          fileExtension: event.fileExtension,
          fileName: file.name,
        });
      })
      .catch((error: any) => {
        console.error(error);
      });
  });
}
