const lookups = "/lookups";
const events = "/events";
const socialMedia = "/SocialMedia";
const printMedia = "/PrintMedia";
const token = "/Token";
const whatsapp = "/Whatsapp";
const newstuck = "/NewsTuck";

export const URL = {
  kCalendar: {
    getDepartments:
      process.env.REACT_APP_KK_BASEURL + lookups + "/getDepartments",
    getParties: process.env.REACT_APP_KK_BASEURL + lookups + "/getParties",
    getDepartmentEventLocations:
      process.env.REACT_APP_KK_BASEURL +
      events +
      "/getDepartmentEventLocations",
    getPartyEventLocations:
      process.env.REACT_APP_KK_BASEURL + events + "/getPartyEventLocations",
    getTotalEventCounts:
      process.env.REACT_APP_KK_BASEURL + events + "/getTotalEventCounts",
    getAllEventLocations:
      process.env.REACT_APP_KK_BASEURL + events + "/getAllEventLocations",
    getEventAvailableConstituency:
      process.env.REACT_APP_KK_BASEURL +
      events +
      "/getEventAvailableConstituency",
    getDaemonAuthToken:
      process.env.REACT_APP_KK_BASEURL + "/getDaemonAuthToken",
    refreshDaemonAuthToken:
      process.env.REACT_APP_KK_BASEURL + "/refreshDaemonAuthToken",
  },
  cmCalendar: {
    getDepartmentEventLocations:
      process.env.REACT_APP_CC_BASEURL +
      events +
      "/getDepartmentEventLocations",
    getTotalEventCounts:
      process.env.REACT_APP_CC_BASEURL + events + "/getTotalEventCounts",
    getDaemonAuthToken:
      process.env.REACT_APP_CC_BASEURL + "/getDaemonAuthToken",
    refreshDaemonAuthToken:
      process.env.REACT_APP_CC_BASEURL + "/refreshDaemonAuthToken",
  },
  socialMedia: {
    topNews: process.env.REACT_APP_MM_BASEURL + socialMedia + "/TopTweets",
    tweetsSentiment:
      process.env.REACT_APP_MM_BASEURL + socialMedia + "/TweetsSentiment",
    tweetsWordCloud:
      process.env.REACT_APP_MM_BASEURL + socialMedia + "/TweetsWordCloud",
    tweetsTrend:
      process.env.REACT_APP_MM_BASEURL + socialMedia + "/TweetsTimeSeries",
  },
  media: {
    topNews: process.env.REACT_APP_MM_BASEURL + printMedia + "/TopNews",
    sentimentNarration:
      process.env.REACT_APP_MM_BASEURL + printMedia + "/SentimentNarration",
    newsArticles:
      process.env.REACT_APP_MM_BASEURL + printMedia + "/NewsArticles",
    paperCoverage:
      process.env.REACT_APP_MM_BASEURL + printMedia + "/PaperCoverage",
    subjectSplit:
      process.env.REACT_APP_MM_BASEURL + printMedia + "/SubjectSplit",
    leaderboardFeed:
      process.env.REACT_APP_MM_BASEURL + newstuck + "/GetLeaderboardFeed",
  },
  token: {
    sasToken: process.env.REACT_APP_MM_BASEURL + token + "/GetSasToken",
  },
  whatsapp: {
    baseUrl: process.env.REACT_APP_MM_BASEURL + whatsapp,
    getByDate: process.env.REACT_APP_MM_BASEURL + whatsapp + "/getWhatsappData",
    addOrUpdate: process.env.REACT_APP_MM_BASEURL + whatsapp + "/addOrUpdate",
  },
  newstuck: {
    getLeaderboardFeed:
      process.env.REACT_APP_MM_BASEURL + newstuck + "/GetLeaderboardFeed",
  },
};
