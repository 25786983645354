import { useState } from "react";
import { CloseIcon } from "../common/icons/CloseIcon";

interface IPopUpValues {
  title: any;
  url: any;
  fileExtension: any;
  onClose: any;
}

const PopupContent = (props: IPopUpValues) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    props.onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="z-[9999] text-[1.3rem] w-[60%] m-auto p-5 bg-gray-100 ">
          <div className="px-5">
            <div className="flex justify-between">
              <div className="w-full"> {props.title}</div>
              <button onClick={handleClose} className="h-full">
                <CloseIcon />
              </button>
            </div>
            {props.fileExtension === "video" ? (
              <video src={`${process.env.REACT_APP_BLOB_URL}/${props.url}`} className="w-full py-5" height="auto" controls />
            ) : (
              <img src={`${process.env.REACT_APP_BLOB_URL}/${props.url}`} alt={props.fileExtension} className="object-cover w-full py-5 "></img>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PopupContent;
