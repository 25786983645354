import { ArrowLeft } from "../../common/icons/ArrowLeft";
import "./WhatsAppDataForm.scss";
import Select from "react-dropdown-select";
import { useForm } from "react-hook-form";
import { uploadFiles } from "../../services/fileUpload.service";
import parties from "../../assets/json/parties.json";

import { Title, UploadFile, SubmitBtn, ClearBtn, dateAndTime, WhatsAppTrendingAddData } from "../../utils/constants";
import { useState } from "react";
import { getPartyOptions } from "../../utils/whatsAppHelper";
import { useNavigate } from "react-router-dom";
import { DataEntryRoutePath } from "../../common/helper/AppMenu.Config";

const WhatsAppDataForm = () => {
  const [partyOptions] = useState(getPartyOptions(parties));
  const { register, setValue, handleSubmit, reset } = useForm();
  const navigation = useNavigate();

  function handleFileInputChange(event: any) {
    const grabValue = event.target.files[0].type;
    const substrings = grabValue.split("/");
    setValue("fileExtension", substrings[0]);
  }

  const onSubmit = async (data: any) => {
    await uploadFiles(data);
    reset();
  };

  return (
    <section>
      <div className="mx-2 card_header rounded-r-lg card bg-white mt-5 p-3">
        <div className="w-full flex justify-between overflow-hidden">
          <div className="w-full flex">
            <div className="flex items-center mx-5 w-14 p-1 cursor-pointer" onClick={() => navigation(DataEntryRoutePath)}>
              <ArrowLeft />
            </div>
            <div className="text-title font-semibold flex items-center ml-[-20px] heading-color">{WhatsAppTrendingAddData}</div>
          </div>
        </div>
      </div>
      <section className="bg-green-200 container mx-auto p-10 mt-10">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-y-8 mt-5">
            <div>
              <div className="border-b border-gray-400 hover:border-blue-400 hover:border-b">
                <label className="title-label">
                  {Title} <span className="required">*</span>
                </label>
                <input
                  className="placeholder-black w-full border-none bg-transparent text-lg font-medium leading-[2.5] outline-none"
                  type="text"
                  required
                  {...register("Title", { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-8 mt-10">
            <div className="border-b border-gray-400 hover:border-blue-400 hover:border-b">
              <label className="title-label mb-2 mt-5">
                {dateAndTime} <span className="required">*</span>
              </label>
              <input
                type="datetime-local"
                id="date"
                className="w-full outline-none"
                required
                {...register("DateandTime", { required: true })}
              ></input>
            </div>
          </div>
          <div className="mt-10 title-label border-b border-gray-400 hover:border-blue-400 hover:border-b">
            <label className="title-label mb-2 mt-5">Party</label>
            <Select options={partyOptions} onChange={(values: any) => setValue("Party", values[0]["label"])} values={[]} className="outline-none" />
          </div>
          <div className="flex flex-col gap-y-8 mt-10">
            <div className="border-b border-gray-400 hover:border-blue-400 hover:border-b">
              <label className="block mb-2  text-gray-900 dark:text-white title-label">
                {UploadFile}
                <span className="required">*</span>
              </label>
              <input
                className="pl-5 mb-2 outline-none w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="file_input"
                type="file"
                accept="image/*, video/*"
                required
                {...register("fileName", { required: true })}
                onChange={handleFileInputChange}
              ></input>
            </div>
          </div>
          <div className="mt-10">
            <button type="submit" className="p-3 bg-[#3e63bf] rounded-md text-textWhite">
              {SubmitBtn}
            </button>
            <button type="reset" className="p-3 ml-10 bg-[#3e63bf] rounded-md text-textWhite">
              {ClearBtn}
            </button>
          </div>
        </form>
      </section>
    </section>
  );
};

export default WhatsAppDataForm;
