import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { WHATS_APP_ALL_DATA } from "../../api/helpers/DataQueryKeys";
import { deleteById, getAll } from "../../api/WhatsappServices";
import { ArrowLeft } from "../../common/icons/ArrowLeft";
import { DeleteIcon } from "../../common/icons/DeleteIcon";
import "./WhatsAppData.scss";
import {
  noDataString,
  ItemsPerPage,
  From,
  To,
  Dates,
  fileUpload,
  fileDownload,
  Parties,
  Title,
  WhatsAppTrending,
  AddNew,
} from "../../utils/constants";
import uuid from "react-uuid";
import { useNavigate } from "react-router-dom";
import { AddDataRoutePath, SocialMediaRoutePath } from "../../common/helper/AppMenu.Config";

const dateOptions: object = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};
const WhatsAppData = () => {
  const [fromDate, setFromDate] = useState("");
  const [tomDate, setToDate] = useState("");
  const [limit, setLimit] = useState(10);
  const navigation = useNavigate();

  const { data: whatsappData } = useQuery([WHATS_APP_ALL_DATA, fromDate, tomDate, limit], () => getAll(limit, fromDate, tomDate));

  const deleteItem = (id: any) => {
    return deleteById(id);
  };

  return (
    <section>
      <div className="mx-2 card_header rounded-r-lg card bg-white mt-5 p-3">
        <div className="w-full flex justify-between overflow-hidden">
          <div className="w-full flex">
            <div className="flex items-center mx-5 w-14 p-1 cursor-pointer" onClick={() => navigation(SocialMediaRoutePath)}>
              <ArrowLeft />
            </div>
            <div className="text-title font-semibold flex items-center ml-[-20px] heading-color">{WhatsAppTrending}</div>
          </div>
        </div>
      </div>

      <div className="flex  justify-end mr-48 my-9 ">
        <button className=" p-4 bg-[#3e63bf] rounded-md text-textWhite" onClick={() => navigation(AddDataRoutePath)}>
          {AddNew}
        </button>
      </div>
      <div className="bg-white mx-[12rem] rounded-md py-10 px-2 h-full">
        <form>
          <section className="flex gap-10 justify-end pb-9">
            <div>
              <label className="flex">
                {ItemsPerPage}
                <input
                  type="text"
                  placeholder="10"
                  className="outline outline-2 w-16 mx-2 outline-slate-400"
                  onChange={(data: any) => setLimit(data.target.value)}
                />
              </label>
            </div>
            <div className="flex gap-5">
              <div>
                <label>{From} </label>
                <input type="date" id="fromDate" className="bg-slate-200" onChange={(data: any) => setFromDate(data.target.value)}></input>
              </div>
              <div className="">
                <label>{To} </label>
                <input type="date" id="fromDate" className="bg-slate-200" onChange={(data: any) => setToDate(data.target.value)}></input>
              </div>
            </div>
          </section>
        </form>
        <section className="bg-green-50 font-bold">
          <div className="grid grid-flow-col grid-cols-12 p-5">
            <div className="col-span-2 text-center ">#</div>

            <div className="col-span-7 text-center">{Title}</div>
            <div className="col-span-1 text-center">{Dates}</div>
            <div className="col-span-1 text-center">{Parties}</div>
            <div className="col-span-1 text-center">{fileUpload}</div>
          </div>
        </section>
        {whatsappData ? (
          <section className="">
            {whatsappData?.map((whatsappData: any) => (
              <div key={uuid()} className="grid grid-flow-col grid-cols-12 even:bg-slate-100 ">
                <div
                  className="col-span-2 text-center p-5 flex justify-center items-center cursor-pointer"
                  onClick={() => deleteItem(whatsappData.id)}
                >
                  <DeleteIcon />
                </div>
                <div className="col-span-7 text-left p-5">{whatsappData.title}</div>
                <div className="col-span-1 text-center p-5 flex justify-center items-center">
                  {new Date(whatsappData.date).toLocaleDateString("en-US", dateOptions)}
                </div>
                <div className="col-span-1 text-center p-5 flex justify-center items-center">{whatsappData.party}</div>
                <div className="col-span-1 text-center p-5 flex justify-center items-center border-none">
                  <a download href={`${process.env.REACT_APP_BLOB_URL}/${whatsappData.filePath}`}>
                    {fileDownload}
                  </a>
                </div>
              </div>
            ))}
          </section>
        ) : (
          <p className="">{noDataString}</p>
        )}
      </div>
    </section>
  );
};

export default WhatsAppData;
