import subjects from "../assets/json/subjects.json";
import parties from "../assets/json/parties.json";
import departments from "../assets/json/departments.json";

export const getDepartmentOptions = (departmentList?: any) => {
  const result: any = [];
  const depart: any = {};
  const departmentsData: any = departmentList ?? departments;
  departmentsData.forEach((item: any) => {
    if (result.length <= 0 || !depart[item.department]) {
      const option: any = {
        label: item.department,
        value: item.department,
      };
      depart[item.department] = option;
      result.push(option);
    }
  });

  return result;
};

export const getSubjectOptions = (subjectList?: any) => {
  const result: any = [];
  const sub: any = {};
  const subjectsData: any = subjectList ?? subjects;
  subjectsData.forEach((item: any) => {
    if (result.length <= 0 || !sub[item.subject]) {
      const option: any = {
        label: item.subject,
        value: item.subject,
      };
      sub[item.subject] = option;
      result.push(option);
    }
  });

  return result;
};

export const getPartyOptions = () => {
  const result: any = [];
  const party: any = {};
  parties.forEach((item: any) => {
    if (result.length <= 0 || !party[item.parties]) {
      const option: any = {
        label: item.party,
        value: item.party,
      };
      party[item.party] = option;
      result.push(option);
    }
  });

  return result;
};
