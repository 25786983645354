interface GovernmentIconProps {
  color?: string;
}

export const GovernmentIcon = (props: GovernmentIconProps) => {
  const color = props.color ? props.color : "#000000";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40">
      <g id="Group_793" data-name="Group 793" transform="translate(11135 17471)">
        <path
          id="Path_10777"
          data-name="Path 10777"
          d="M9.349,16.967v3.809m5.079-3.809v3.809m5.079-3.809v3.809M3,25.855H25.855M3,11.888H25.855M3,8.079,14.428,3,25.855,8.079M4.27,11.888H24.586V25.855H4.27Z"
          transform="translate(-11129.428 -17465.428)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <rect id="Rectangle_4406" data-name="Rectangle 4406" width="40" height="40" transform="translate(-11135 -17471)" fill="none" />
      </g>
    </svg>
  );
};
