import * as authStorage from "../api/AuthService";
import { getCmCalenderAccessToken, getKCalenderAccessToken } from "../api/AxiosServices";

export function getTokenFromUrl() {
  const querystringParams = new URLSearchParams(window.location.search);
  return querystringParams.get("token") ?? "";
}

export async function UpdateKKAuthToken(appAuthToken: string) {
  if (appAuthToken.length <= 0) return false;

  try {
    const kToken = await getKCalenderAccessToken(appAuthToken);

    if (kToken.length <= 0) {
      authStorage.setKKAuthDetails("");
      return false;
    }
    authStorage.setKKAuthDetails(kToken);
  } catch (err) {
    authStorage.setKKAuthDetails("");
    return false;
  }
  return true;
}

export async function UpdateCCAuthToken(appAuthToken: string) {
  if (appAuthToken.length <= 0) return false;

  try {
    const cToken = await getCmCalenderAccessToken(appAuthToken);

    if (cToken.length <= 0) {
      authStorage.setCCAuthDetails("");
      return false;
    }
    authStorage.setCCAuthDetails(cToken);
  } catch (err) {
    authStorage.setCCAuthDetails("");
    return false;
  }
  return true;
}

export async function checkAndUpdateAuthToken(appAuthToken: string) {
  if (appAuthToken.length <= 0) return false;
  try {
    const [kToken, cToken] = await Promise.all([getKCalenderAccessToken(appAuthToken), getCmCalenderAccessToken(appAuthToken)]);

    authStorage.setKKAuthDetails(kToken);
    authStorage.setCCAuthDetails(cToken);
  } catch (err) {
    resetAuthToken();
    return false;
  }
  return true;
}

export function resetAuthToken() {
  authStorage.setKKAuthDetails("");
  authStorage.setCCAuthDetails("");
}

export function storeAppLanguage(lang: string) {
  const language = lang ? lang : "en";
  authStorage.setLang(language);
}

export function getAppLanguage() {
  return authStorage.getLang();
}
