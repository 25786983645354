import { URL } from "../config/URL.Config";
import AxiosServices from "./AxiosServices";

const instance = new AxiosServices().getServiceInstance();

export const getDepartLocFromKabinet = async (fromDate: string, toDate: string, constituency?: string[], departments?: string[]) => {
  try {
    const queryParams = composeQueryParams(constituency, departments);
    const url = `${URL.kCalendar.getDepartmentEventLocations}/${fromDate}/${toDate}/${queryParams}`;

    const res = await instance.get(url);
    return res.data.data ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getDepartLocFromCM = async (fromDate: string, toDate: string, constituency?: string[], departments?: string[]) => {
  try {
    const queryParams = composeQueryParams(constituency, departments);
    const url = `${URL.cmCalendar.getDepartmentEventLocations}/${fromDate}/${toDate}/${queryParams}`;

    const res = await instance.get(url);
    return res.data.data ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

const composeQueryParams = (constituency?: string[], departments?: string[]) => {
  let url = "";
  const departmentValid = departments && departments.length > 0;
  const constituencyValid = constituency && constituency.length > 0;

  if (departmentValid) {
    const departmentQuery = encodeURIComponent(departments.join(","));
    url = `${url}?departments=${departmentQuery}`;
  } else if (departments?.length === 0) {
    url = `${url}?departments=none`;
  }

  if (constituencyValid) {
    const constituencyQuery = encodeURIComponent(constituency.join(","));
    url = `${url}&constituency=${constituencyQuery}`;
  }
  return url;
};

export const getDepartments = async () => {
  try {
    const departments: string[] = [];
    const res = await instance.get(`${URL.kCalendar.getDepartments}`);

    res.data.data.forEach((item: any) => {
      departments.push(item.value);
    });

    return departments;
  } catch (error) {
    console.log(error);
    return [];
  }
};
