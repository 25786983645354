import { useQuery } from "@tanstack/react-query";
import React, { FC, useState } from "react";
import ReactWordcloud, { MinMaxPair, Word } from "react-wordcloud";
import { PRINT_WORD_CLOUD_DATA, SAS_TOKEN_DATA, TWEETS_WORD_CLOUD_DATA } from "../../api/helpers/DataQueryKeys";
import { getUserSelectedDate } from "../../utils/dateHelper";
import { showLoadingIcon, showText } from "../../utils/helper";
import "./WordCloud.scss";
import { Colors } from "../Charts/Helpers/ChartUtility";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import { getSasToken } from "../../api/BlobServices";
import { noDataString, WordCloudBlob } from "../../utils/constants";
import { getPrintWordCloudFilePath, getTwitterWordCloudFilePath } from "../../utils/wordCloudHelper";
import { getWordCloud } from "../../api/WordCloudServices";

interface IWordCloudProps {
  title: string;
  date?: Date;
  isTwitterWordCloud?: boolean;
}

const options = {
  colors: [Colors.yellow, Colors.lightYellow],
  fontFamily: "arial",
  fontSizes: [10, 80] as MinMaxPair,
  deterministic: false,
  enableOptimizations: true,
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 1,
  rotations: 1,
  rotationAngles: [0, 10] as MinMaxPair,
  tooltipOptions: {
    allowHTML: true,
    appendTo: document.body,
    arrow: true,
    interactive: false,
  },
};

const WordCloud: FC<IWordCloudProps> = (props) => {
  const [userDate] = useState(getUserSelectedDate());
  const [filePath] = useState(props.isTwitterWordCloud ? getTwitterWordCloudFilePath(userDate) : getPrintWordCloudFilePath(userDate));

  const { data: sasTokenData } = useQuery([SAS_TOKEN_DATA], () => getSasToken(WordCloudBlob));

  const sasToken = sasTokenData;

  const { data: wordCloudData, isLoading } = useQuery(
    [props.isTwitterWordCloud ? TWEETS_WORD_CLOUD_DATA : PRINT_WORD_CLOUD_DATA, sasToken],
    () => getWordCloud(filePath + sasToken),
    {
      enabled: !!sasToken,
    }
  );

  const showFetching = () => {
    return <>{isLoading ? showLoadingIcon() : showText(noDataString)}</>;
  };

  const wordChartData = wordCloudData as Word[];

  const callbacks = {
    getWordColor: (word: any) => {
      if (word.sentiment === "positive") {
        return Colors.green;
      } else if (word.sentiment === "negative") {
        return Colors.red;
      } else if (word.sentiment === "neutral") {
        return Colors.yellow;
      }
    },

    onWordClick: (word: any) => window.open(word[word.sentiment + "TweetsUrl"][0], "_blank"),
  };

  return (
    <div className="WordCloud h-full w-full" data-testid="WordCloud">
      <div className="text-cardTitle dark:text-textWhite font-bold ml-5 mt-2 w-full">{props.title}</div>
      <div className="!wordCloud h-full w-full pb-10">
        {wordChartData && wordChartData.length ? (
          <ReactWordcloud callbacks={props.isTwitterWordCloud ? callbacks : {}} words={wordChartData} options={options} />
        ) : (
          showFetching()
        )}
      </div>
    </div>
  );
};

export default WordCloud;
