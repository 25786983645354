import { BlobServiceClient } from "@azure/storage-blob";
import { URL } from "../config/URL.Config";
import AxiosServices from "./AxiosServices";

const instance = new AxiosServices().getServiceInstance();

export const getSasToken = async (blobName: string) => {
  const url = `${URL.token.sasToken}/${blobName}`;
  const res = await instance.get(url, { responseType: "text" as "json" });
  return res?.data ?? "";
};

export const getBlobClient = async (filePath: any) => {
  if (process.env.REACT_APP_BLOB_CONTAINER_NAME)
    return getSasToken(process.env.REACT_APP_BLOB_CONTAINER_NAME).then((sas) => {
      const blobServiceClient = new BlobServiceClient((process.env.REACT_APP_BLOB_URL ?? "") + sas);
      const container = blobServiceClient.getContainerClient(process.env.REACT_APP_BLOB_CONTAINER_NAME ?? "");

      return container.getBlockBlobClient(filePath);
    });
};
