export const ArrowLeft = () => {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeWidth="2.5"
      width={"20px"}
      height={"23px"}
      viewBox="0 0 24 24"
      color="#2780e3"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"></path>
    </svg>
  );
};
