import { useQuery } from "@tanstack/react-query";
import React, { FC, useEffect, useState } from "react";
import { PRINT_TOP_NEWS } from "../../api/helpers/DataQueryKeys";
import { getPrintMediaTopNews } from "../../api/MediaServices";
import { Negative, Positive } from "../../utils/constants";
import { getUserSelectedDate, getValidDate } from "../../utils/dateHelper";
import { getValueFromDropDownOptions } from "../../utils/helper";
import TopTrending from "../TopTrending/TopTrending";
import "./Top5News.scss";

interface Top5NewsProps {}

const Top5News: FC<Top5NewsProps> = () => {
  const [userDate] = useState(getUserSelectedDate());
  const [date] = useState(getValidDate(userDate, "YYYY-MM-DD"));
  const [sentiment, setSentiment] = useState<string>(Positive);
  const [selectedSubjects, setSelectedSubjects] = useState();
  const [selectedDepartments, setSelectedDepartments] = useState();
  const { data: printData, refetch: updateTopNews } = useQuery([PRINT_TOP_NEWS, date, sentiment, selectedDepartments, selectedSubjects], () =>
    getPrintMediaTopNews(5, date, sentiment, selectedDepartments, selectedSubjects)
  );

  useEffect(() => {
    updateTopNews();
  }, [sentiment, selectedDepartments, selectedSubjects]);

  const positiveOnClick = () => {
    setSentiment(Positive);
  };

  const negativeOnClick = () => {
    setSentiment(Negative);
  };

  const subjectChanged = (event: any) => {
    const values = getValueFromDropDownOptions(event);
    setSelectedSubjects(values);
  };

  const departmentChanged = (event: any) => {
    const values = getValueFromDropDownOptions(event);
    setSelectedDepartments(values);
  };

  return (
    <div className="Top5News h-full" data-testid="Top5News">
      <TopTrending
        title="Top Trending News"
        hasSentiment={true}
        hasFilter={true}
        newsData={printData}
        positiveOnClick={positiveOnClick}
        negativeOnClick={negativeOnClick}
        subjectChanged={subjectChanged}
        departmentChanged={departmentChanged}
      />
    </div>
  );
};

export default Top5News;
