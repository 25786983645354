export const DeleteIcon = () => {
  return (
    <svg width="25px" height="25px" fill="#FF0000" className=" border border-red-500" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m577.5 122.5h-122.5v-52.5c-0.011719-9.2812-3.7031-18.176-10.262-24.738-6.5625-6.5586-15.457-10.25-24.738-10.262h-140c-9.2812 0.011719-18.176 3.7031-24.738 10.262-6.5586 6.5625-10.25 15.457-10.262 24.738v52.5h-122.5c-6.2539 0-12.031 3.3359-15.156 8.75s-3.125 12.086 0 17.5 8.9023 8.75 15.156 8.75h52.5v332.5c0.011719 9.2812 3.7031 18.176 10.262 24.738 6.5625 6.5586 15.457 10.25 24.738 10.262h280c9.2812-0.011719 18.176-3.7031 24.738-10.262 6.5586-6.5625 10.25-15.457 10.262-24.738v-332.5h52.5c6.2539 0 12.031-3.3359 15.156-8.75s3.125-12.086 0-17.5-8.9023-8.75-15.156-8.75zm-297.5-52.5h140v52.5h-140zm210.02 420h-280.02v-332.5h280z" />
        <path d="m315 175c-4.6406 0-9.0938 1.8438-12.375 5.125s-5.125 7.7344-5.125 12.375v262.5c0 6.2539 3.3359 12.031 8.75 15.156s12.086 3.125 17.5 0 8.75-8.9023 8.75-15.156v-262.5c0-4.6406-1.8438-9.0938-5.125-12.375s-7.7344-5.125-12.375-5.125z" />
        <path d="m385 175c-4.6406 0-9.0938 1.8438-12.375 5.125s-5.125 7.7344-5.125 12.375v262.5c0 6.2539 3.3359 12.031 8.75 15.156s12.086 3.125 17.5 0 8.75-8.9023 8.75-15.156v-262.5c0-4.6406-1.8438-9.0938-5.125-12.375s-7.7344-5.125-12.375-5.125z" />
        <path d="m437.5 402.5c0 6.2539 3.3359 12.031 8.75 15.156s12.086 3.125 17.5 0 8.75-8.9023 8.75-15.156v-175c0-6.2539-3.3359-12.031-8.75-15.156s-12.086-3.125-17.5 0-8.75 8.9023-8.75 15.156z" />
        <path d="m245 210c-4.6406 0-9.0938 1.8438-12.375 5.125s-5.125 7.7344-5.125 12.375v175c0 6.2539 3.3359 12.031 8.75 15.156s12.086 3.125 17.5 0 8.75-8.9023 8.75-15.156v-175c0-4.6406-1.8438-9.0938-5.125-12.375s-7.7344-5.125-12.375-5.125z" />
      </g>
    </svg>
  );
};
