import { useRef, useEffect, useState, FC } from "react";
import type { ChartData } from "chart.js";
import { Chart as ChartJS, registerables, Tooltip } from "chart.js";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./BarChart.scss";
import { getChartWithGradientColor } from "../Helpers/ChartUtility";

ChartJS.register(Tooltip);
ChartJS.register(...registerables, ChartDataLabels);

interface IBarChartProps {
  chartData: any;
  chartOptions: any;
  colorStartFrom?: string;
  plugin?: any;
  height?: any;
}

const BarChart: FC<IBarChartProps> = (props: IBarChartProps) => {
  const chartRef = useRef<ChartJS>(null);
  const [chartData, setChartData] = useState<ChartData<"bar">>({ datasets: [] });

  useEffect(() => {
    if (!chartRef.current || !props.chartData) {
      return;
    }

    const data = getChartWithGradientColor(props.chartData, chartRef.current, props.colorStartFrom);
    setChartData(data);
  }, [props.chartData]);

  return (
    <div className="BarChart h-full w-full" data-testid="BarChart">
      <Chart ref={chartRef} type="bar" data={chartData} options={props.chartOptions} plugins={props.plugin} />
    </div>
  );
};

export default BarChart;
