import { useQuery } from "@tanstack/react-query";
import React, { FC, useState } from "react";
import { TOP_5_TWEETS_DATA } from "../../api/helpers/DataQueryKeys";
import { getSocialMediaTopNews } from "../../api/SocialMediaServices";
import { calcToDateForTweet, convertToUTC, getUserSelectedDate } from "../../utils/dateHelper";
import TopTrending from "../TopTrending/TopTrending";
import "./Top5Tweets.scss";

interface Top5TweetsProps {}

const Top5Tweets: FC<Top5TweetsProps> = (props: Top5TweetsProps) => {
  const [userDate] = useState(getUserSelectedDate());
  const tweetToDate = calcToDateForTweet(userDate);
  const toDate = convertToUTC({ date: tweetToDate });
  tweetToDate.setHours(tweetToDate.getHours() - 6);
  const fromDate = convertToUTC({ date: tweetToDate });

  const { data: socialData } = useQuery([TOP_5_TWEETS_DATA, fromDate, toDate], () => getSocialMediaTopNews(5, fromDate, toDate));

  return (
    <div className="Top5Tweets h-full" data-testid="Top5Tweets">
      <TopTrending title="Top 5 Tweets" newsData={socialData} />
    </div>
  );
};

export default Top5Tweets;
