import React, { FC } from "react";
import uuid from "react-uuid";
import { getWord } from "../../services/lang.services";
import { Alliances, Department, Opposition, Party, Total } from "../../utils/constants";
import "./SummaryCard.scss";

interface SummaryCardProps {
  data: any;
  label: string;
}

const SummaryCard: FC<SummaryCardProps> = (props: SummaryCardProps) => {
  const getCount = (name: string) => {
    if (!props.data) return 0;
    if (name === Total) {
      return props.data.reduce((count: number, item: any) => {
        return count + item.eventsCount;
      }, 0);
    }
    return props.data.filter((item: any) => item.eventType === name)[0]?.eventsCount || 0;
  };

  const partyMenu = [Department, Party];
  const allianceMenu = [Alliances, Opposition];

  const showLabels = (data: any) => {
    return data.map((item: string) => (
      <div key={uuid()} className="text-subTitle dark:text-textWhite">
        <div className="grid grid-cols-12 ml-5 mt-2">
          <div className="col-span-2 mr-5"> {getWord(item)} </div>
          <div className="col-span-5"></div>
          <div className={`${item.toLowerCase()}Count col-span-4 text-center`}>{getCount(item)}</div>
          <div className="mr-5"></div>
        </div>
      </div>
    ));
  };

  return (
    <div className="grid summaryCard dark:darkSummaryCard mx-5 mt-3">
      <div className="mt-3 ml-5 gap-x-5 text-cardTitle font-semibold flex dark:text-textWhite">
        <div>{props.label}</div>
        <div>{getCount(Total)}</div>
      </div>
      <div className="flex justify-evenly mb-3 ">
        <div className="grid ">{showLabels(partyMenu)}</div>
        <div className="splitter "></div>
        <div className="grid">{showLabels(allianceMenu)}</div>
      </div>
    </div>
  );
};

export default SummaryCard;
