import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./styles/App.scss";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./api/MsalService";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { Layout } from "./pages/Layout";
import AppRoutes from "./routes";

const queryClient = new QueryClient();

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Layout>
            <AppRoutes />
          </Layout>
        </QueryClientProvider>
      </Provider>
    </MsalProvider>
  );
}

export default App;
