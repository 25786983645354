import { isTamilLang } from "../utils/helper";

const langConstants: any = {
  "Assembly Constituency": "சட்டமன்ற தொகுதிகள்",
  Department: "துறை",
  Party: "கட்சி",
  Alliance: "கூட்டணி",
  Alliances: "கூட்டணி",
  Opposition: "எதிர்கட்சி",
  CM: "முதலமைச்சர்",
  Months: "மாதங்கள்",
  Year: "ஆண்டு",
};

export function getTamilWord(word: string) {
  return langConstants[word] ?? word;
}

export function getWord(word: string) {
  return isTamilLang() ? getTamilWord(word) : word;
}
