import { URL } from "../config/URL.Config";
import AxiosServices from "./AxiosServices";

const instance = new AxiosServices().getServiceInstance();

export const getAll = async (limit: number, fromDate?: string, toDate?: string) => {
  let allData: any;
  let url = `${URL.whatsapp.baseUrl}/${limit}`;
  if (fromDate && toDate) {
    url = `${url}?FromDate=${fromDate}`;
    url = `${url}&ToDate=${toDate}`;
  }
  const res = await instance.get(url);
  allData = Object.keys(res.data).length > 0 ? res.data : null;
  return allData;
};

export const addOrUpdate = async (data: any) => {
  let date: any;
  const res = await instance.post(`${URL.whatsapp.addOrUpdate}`, data);
  date = Object.keys(res.data).length > 0 ? res.data : null;
  return date;
};

export const deleteById = async (id: string) => {
  let date: any;
  const res = await instance.delete(`${URL.whatsapp.baseUrl}/${id}`);
  date = Object.keys(res.data).length > 0 ? res.data : null;
  return date;
};

export const getByDate = async (fromDate: string, toDate: string, party: any) => {
  try {
    const queryParams = composeQueryParams(party);
    const url = `${URL.whatsapp.getByDate}/${fromDate}/${toDate}/${queryParams}`;

    const res = await instance.get(url);
    return res.data ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

const composeQueryParams = (parties?: string[]) => {
  let url = "";
  const partiesValid = parties && parties.length > 0;

  if (partiesValid) {
    const partyQuery = encodeURIComponent(parties.join(","));
    url = `${url}?parties=${partyQuery}`;
  }
  return url;
};
