interface IVideoIconProps {
  color?: string;
}

export const VideoIcon = (props: IVideoIconProps) => {
  const color = props.color ? props.color : "#000000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40">
      <g id="Group_10" data-name="Group 10" transform="translate(-247 -1723)">
        <g id="Group_7" data-name="Group 7" transform="translate(38 -15.577)">
          <g id="Ellipse_7" data-name="Ellipse 7" transform="translate(213 1742.577)" fill="none" stroke={color} strokeWidth="2">
            <circle cx="16.5" cy="16.5" r="16.5" stroke="none" />
            <circle cx="16.5" cy="16.5" r="15.5" fill="none" />
          </g>
          <path
            id="Path_7"
            data-name="Path 7"
            d="M4218.762,1666.82v15.424l12.829-7.473Z"
            transform="translate(-3993.964 84.598)"
            fill="none"
            stroke={color}
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
        <g id="Group_9" data-name="Group 9" transform="translate(85 40)">
          <rect id="Rectangle_30" data-name="Rectangle 30" width="40" height="40" transform="translate(162 1683)" fill="none" />
        </g>
      </g>
    </svg>
  );
};
