import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { WHATS_APP_TOP_DATA } from "../../api/helpers/DataQueryKeys";
import { getByDate } from "../../api/WhatsappServices";
import { getUserSelectedDate } from "../../utils/dateHelper";
import { getValueFromDropDownOptions } from "../../utils/helper";
import TopTrending from "../TopTrending/TopTrending";
import "./WhatsAppTop.scss";

interface WhatsAppTopProps {}

const WhatsAppTop: FC<WhatsAppTopProps> = () => {
  const [userDate] = useState(getUserSelectedDate());
  const [date] = useState(moment(userDate).format("YYYY-MM-DD"));
  const [selectedParties, setSelectedParties] = useState([]);

  const { data: whatsAppData, refetch: updateTopWhatsAppData } = useQuery([WHATS_APP_TOP_DATA, selectedParties], () =>
    getByDate(date, date, selectedParties)
  );

  useEffect(() => {
    updateTopWhatsAppData();
  }, [selectedParties]);

  const partyChanged = (event: any) => {
    const values = getValueFromDropDownOptions(event);
    setSelectedParties(values);
  };

  return (
    <div className="WhatsAppTop h-full" data-testid="WhatsAppTop">
      <TopTrending partyChanged={partyChanged} title="WhatsApp Most Circulated" hasWhatsappFilter={true} newsData={whatsAppData} />
    </div>
  );
};

export default WhatsAppTop;
