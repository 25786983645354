import { URL } from "../config/URL.Config";
import { ITopNewsItems } from "../models/Common";
import { ITweetsentimentItems } from "../models/SocialMedia";
import AxiosServices from "./AxiosServices";

const instance = new AxiosServices().getServiceInstance();

export const getSocialMediaTopNews = async (count: number, from: string, to: string) => {
  let topNews: ITopNewsItems;
  const res = await instance.get(`${URL.socialMedia.topNews}/${count}/${from}/${to}`);
  topNews = res.data;
  return topNews;
};

export const getTweetsSentiment = async (from: string, to: string) => {
  let tweetsSentiment: ITweetsentimentItems;
  const res = await instance.get(`${URL.socialMedia.tweetsSentiment}/${from}/${to}`);
  tweetsSentiment = res.data;
  return tweetsSentiment;
};
