type MediaIconProps = {
  color?: string;
};

export const MediaIcon = (props: MediaIconProps) => {
  const color = props.color ? props.color : "#000000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="8 8 25 25">
      <g id="Group_797" data-name="Group 797" transform="translate(11170 17338)">
        <rect id="Rectangle_4408" data-name="Rectangle 4408" width="40" height="40" transform="translate(-11170 -17338)" fill="none" />
        <g id="Group_796" data-name="Group 796" transform="translate(-12569.174 -18523.594)">
          <g id="Group_609" data-name="Group 609" transform="translate(1409.174 1195.594)">
            <path
              id="Path_10698"
              data-name="Path 10698"
              d="M1140.88,1407.594h16.484v16.787s.067,3.282-2.932,3.192a1,1,0,0,1-.175.005c-2.1-.026-14.872,0-14.872,0a2.3,2.3,0,0,0,1.495-2.252C1140.882,1423.538,1140.88,1407.594,1140.88,1407.594Z"
              transform="translate(-1137.365 -1407.594)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              id="Path_10699"
              data-name="Path 10699"
              d="M1091.139,1650.493h-3.577s-.081,5.7,0,8.215c.554,2.442,2.286,1.958,2.286,1.958"
              transform="translate(-1087.526 -1640.705)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <rect
              id="Rectangle_4316"
              data-name="Rectangle 4316"
              width="5.021"
              height="4.491"
              rx="1"
              transform="translate(6.146 3.342)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              id="Line_87"
              data-name="Line 87"
              x2="2.863"
              transform="translate(13.993 4.055)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="1"
            />
            <line
              id="Line_88"
              data-name="Line 88"
              x2="2.863"
              transform="translate(13.993 6.369)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="1"
            />
            <line
              id="Line_89"
              data-name="Line 89"
              x2="11.419"
              transform="translate(5.702 10.857)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="1"
            />
            <line
              id="Line_90"
              data-name="Line 90"
              x2="11.419"
              transform="translate(5.702 13.949)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="1"
            />
            <line
              id="Line_91"
              data-name="Line 91"
              x2="11.419"
              transform="translate(5.702 16.892)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
