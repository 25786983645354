interface ThemeIconProps {
  color?: string;
}

export const ThemeIcon = (props: ThemeIconProps) => {
  const color = props.color ? props.color : "#000000";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30" viewBox="0 0 1000 1000">
      <rect x="0" y="0" width="40" height="40" fill="rgba(255,255,255,0)" />
      <g transform="matrix(-25 0 0 -25 500 500)" id="218803">
        <path
          fill={color}
          vectorEffect="non-scaling-stroke"
          transform=" translate(-12, -12)"
          d="M 12 22 C 17.5228 22 22 17.5228 22 12 C 22 6.47715 17.5228 2 12 2 C 6.47715 2 2 6.47715 2 12 C 2 17.5228 6.47715 22 12 22 Z M 12 20.5 V 3.5 C 16.6944 3.5 20.5 7.30558 20.5 12 C 20.5 16.6944 16.6944 20.5 12 20.5 Z"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};
