import moment from "moment";
import { calcToDateForTweet, convertToUTC } from "./dateHelper";

export const getTwitterWordCloudFilePath = (date: any) => {
  const tweetToDate = calcToDateForTweet(date);
  const endHr = convertToUTC({ date: tweetToDate, strFormat: "HH" });
  tweetToDate.setHours(tweetToDate.getHours() - 6);
  const fromDate = convertToUTC({ date: tweetToDate, strFormat: "YYYY-MM-DD" });
  const startHr = convertToUTC({ date: tweetToDate, strFormat: "HH" });
  const filePath = `social-media/${fromDate}/${startHr}-${endHr}.json`;
  return filePath;
};

export const getPrintWordCloudFilePath = (date: any) => {
  const day = moment(date).format("YYYY-MM-DD");
  const filePath = `print-media/${day}.json`;
  return filePath;
};
