type MarkerIconProps = {
  color?: string;
  text?: string;
};

export const MarkerIcon = (props: MarkerIconProps) => {
  const color = props.color ? props.color : "#000000";
  return (
    <svg id="Component_5_364" data-name="Component 5 – 364" xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 14 21.819">
      <path id="Path_10821" data-name="Path 10821" d="M7,0a7,7,0,0,1,7,7c0,3.866-7,14.819-7,14.819S0,10.866,0,7A7,7,0,0,1,7,0Z" fill={color} />
      <circle id="Ellipse_67" data-name="Ellipse 67" cx="4" cy="4" r="4" transform="translate(3 3)" fill="#fff" />
    </svg>
  );
};
