import React, { FC, useEffect, useRef, useState } from "react";
import type { ChartData } from "chart.js";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import DataLabelsPlugin from "chartjs-plugin-datalabels";
import "chartjs-adapter-date-fns";
import "./LiveChart.scss";
import { getChartWithGradientColor } from "../Helpers/ChartUtility";

interface ILiveChartProps {
  chartData: any;
  options: any;
  className?: any;
  applyGradient?: boolean;
  height?: string;
  width?: string;
}

ChartJS.register(...registerables, DataLabelsPlugin);

const LiveChart: FC<ILiveChartProps> = (props: ILiveChartProps) => {
  const chartRef = useRef<ChartJS>(null);
  const [chartData, setChartData] = useState<ChartData<"line">>({ datasets: [] });

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    let data = props.chartData;
    if (props.applyGradient) {
      data = getChartWithGradientColor(props.chartData, chartRef.current);
    }
    setChartData(data);
  }, []);

  return (
    <div className={`w-full  ${props.className} `} data-testid="LiveChart">
      <Chart ref={chartRef} type="line" data={chartData} options={props.options} />
    </div>
  );
};

export default LiveChart;
