import { Routes, Route, Navigate } from "react-router-dom";
import { AddDataRoutePath, CalendarRoutePath, DataEntryRoutePath, MediaRoutePath, SocialMediaRoutePath } from "./common/helper/AppMenu.Config";
import CalendarPage from "./pages/CalendarPage/CalendarPage";
import MediaPage from "./pages/MediaPage/MediaPage";
import SocialMediaPage from "./pages/SocialMediaPage/SocialMediaPage";
import WhatsAppDataForm from "./components/WhatsAppDataForm/WhatsAppDataForm";
import WhatsAppData from "./pages/WhatsAppData/WhatsAppData";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Login from "./pages/Login/Login";
import Registration from "./pages/Registration/Registration";

const AppRoutes = () => (
  <div className="" data-testid="Master">
    <AuthenticatedTemplate>
      <Routes>
        <Route path="/" element={<SocialMediaPage />} />
        <Route path={MediaRoutePath} element={<MediaPage />} />
        <Route path={SocialMediaRoutePath} element={<SocialMediaPage />} />
        <Route path={CalendarRoutePath} element={<CalendarPage />} />
        <Route path="*" element={<Navigate replace to="/" />} />
        <Route path={DataEntryRoutePath} element={<WhatsAppData />} />
        <Route path={AddDataRoutePath} element={<WhatsAppDataForm />} />
      </Routes>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/*" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Registration />} />
      </Routes>
    </UnauthenticatedTemplate>
  </div>
);

export default AppRoutes;
