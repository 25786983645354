import { FC, useCallback, useEffect, useRef, useState } from "react";
import "./CalendarPage.scss";
import {
  calculateFromDate,
  getDropDownOptions,
  getGeoJson,
  getPathOptions,
  getMarkerColor,
  getValueFromDropDownOptions,
  showGovLegends,
  isAppDarkTheme,
} from "../../utils/helper";
import LeafletMap from "../../components/Map/Map";
import { getDepartments, getDepartLocFromKabinet, getDepartLocFromCM } from "../../api/DepartmentServices";
import { getEventAvailableConstituency, getPartyLocations, getKKTotalEvents, getCCTotalEvents } from "../../api/ConstituencyServices";
import moment from "moment";
import { partyTypes } from "../../utils/partyTypeHelper";
import SummaryCard from "../../components/SummaryCard/SummaryCard";
import uuid from "react-uuid";
import CardHeader from "../../components/CardHeader/CardHeader";
import { MultiSelect } from "react-multi-select-component";
import { Alliances, AssemblyConstituency, Department, Opposition, Party } from "../../utils/constants";
import { eventType } from "../../enums/eventType";
import { ResetIcon } from "../../common/icons/ResetIcon";
import { useMediaQuery } from "react-responsive";
import { scrollIntoView } from "seamless-scroll-polyfill";
import { getWord } from "../../services/lang.services";

interface CalendarPageProps {}

const CalendarPage: FC<CalendarPageProps> = (props: CalendarPageProps) => {
  const [constituencyGeoJson, setConstituencyGeoJson] = useState<any>(getGeoJson());
  const [navMapPathOptions] = useState(getPathOptions());
  const [pathOptions, setPathOptions] = useState(getPathOptions());
  const [geoJsonData, setGeoJsonData] = useState<any>(constituencyGeoJson);
  const [departmentOptions, setDepartmentOptions] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [mapActiveIndex] = useState(0);
  const [departEventLocations, setDepartEventLocations] = useState<any>([]);
  const [partyEventLocations, setPartyEventLocations] = useState<any>([]);
  const [alliancesEventLocations, setAlliancesEventLocations] = useState<any>([]);
  const [oppositionEventLocations, setOppositionEventLocations] = useState<any>([]);
  const [noOfMonths, setNoOfMonths] = useState(3);
  const [allEventsData, setAllEventsData] = useState<any>(null);
  const [currentConstituency, setCurrentConstituency] = useState("");
  const [selectedFeature, setSelectedFeature] = useState<any>(null);
  const [currentBounds, setCurrentBounds] = useState<any>();
  const [selectedDepartment, setSelectedDepartment] = useState<any>([]);
  const [summaryTitle, setSummaryTitle] = useState<string>(getWord(AssemblyConstituency));
  const [departmentsList, setDepartmentsList] = useState<any>([]);
  const [eventsAvailableCons, setEventsAvailableCons] = useState<any>([]);
  const [resetZoom, setResetZoom] = useState<boolean>(false);
  const isSmallDevice = useMediaQuery({ maxWidth: 897 });
  const isIpadDevice = useMediaQuery({ minWidth: 1001, maxWidth: 1152 });
  const departmentCardRef = useRef<any>();

  useEffect(() => {
    async function getDepartmentsList() {
      const toDate = moment(moment.now()).format("YYYY-MM-DD");
      const fromDate = calculateFromDate(toDate, noOfMonths);

      const [department, availableCons] = await Promise.all([getDepartments(), getEventAvailableConstituency(fromDate, toDate)]);

      setDepartmentsList(department);
      setEventsAvailableCons(availableCons);
    }
    getDepartmentsList();
  }, []);

  useEffect(() => {
    if (!constituencyGeoJson) {
      setConstituencyGeoJson(getGeoJson());
      setSummaryTitle(getWord(AssemblyConstituency));
    }
  }, [constituencyGeoJson]);

  // Total Event count
  useEffect(() => {
    async function getEventsCount() {
      await getTotalEventsCount(noOfMonths);
    }
    getEventsCount();
  }, [noOfMonths, currentConstituency]);

  // Department list
  useEffect(() => {
    async function getDepartmentList() {
      const departmentOptions = getDropDownOptions(departmentsList);
      setDepartmentOptions(departmentOptions ?? []);
      setSelectedDepartment(departmentOptions ?? []);
    }
    getDepartmentList();
  }, [departmentsList]);

  // Update department event locations
  useEffect(() => {
    async function updateDepartmentLocations() {
      await getDepartmentLocation(noOfMonths);
    }

    updateDepartmentLocations();
  }, [noOfMonths, currentConstituency, selectedDepartment]);

  //Update party event locations
  useEffect(() => {
    async function updatePartyLocations() {
      await getPartyLocation();
    }
    updatePartyLocations();
  }, [noOfMonths, currentConstituency]);

  //Update alliance event locations
  useEffect(() => {
    async function updateAlliancesLocations() {
      await getAllianceLocation();
    }
    updateAlliancesLocations();
  }, [noOfMonths, currentConstituency]);

  //Update opposition event locations
  useEffect(() => {
    async function updateOppositionLocations() {
      await getOppositionLocation();
    }
    updateOppositionLocations();
  }, [noOfMonths, currentConstituency]);

  const constituencyMapOnClickCallback = useCallback((event: any) => constituencyMapOnClick(event), []);

  const getTotalEventsCount = async (noOfMonths: number) => {
    const toDate = moment(moment.now()).format("YYYY-MM-DD");
    const fromDate = calculateFromDate(toDate, noOfMonths);

    const [kkEventsData, ccEventsData] = await Promise.all([
      getKKTotalEvents(fromDate, toDate, currentConstituency),
      getCCTotalEvents(fromDate, toDate, currentConstituency),
    ]);

    const data = [...kkEventsData, ...ccEventsData];

    const eventsData = data.reduce((previousValue, currentValue) => {
      const found = previousValue.find((x: any) => x.eventType === currentValue.eventType);
      if (found) found.eventsCount += currentValue.eventsCount;
      else previousValue.push(currentValue);
      return previousValue;
    }, []);

    setAllEventsData(eventsData);
  };

  const getDepartmentLocation = async (noOfMonths: number) => {
    const toDate = moment(moment.now()).format("YYYY-MM-DD");
    const fromDate = calculateFromDate(toDate, noOfMonths);

    if (selectedDepartment.length === 0) {
      setDepartEventLocations([]);
      return;
    }

    const departments = getValueFromDropDownOptions(selectedDepartment);
    const constituencyList: any = [];
    if (currentConstituency) constituencyList.push(currentConstituency);

    const [kkLocationData, cmLocationData] = await Promise.all([
      getDepartLocFromKabinet(fromDate, toDate, constituencyList, departments),
      getDepartLocFromCM(fromDate, toDate, constituencyList, departments),
    ]);
    const locationData = [...kkLocationData, ...cmLocationData];
    setDepartEventLocations(locationData);
  };

  const getPartyLocation = async () => {
    const locationData = await getLocationByPartyType(partyTypes[partyTypes.DMK]);
    setPartyEventLocations(locationData);
  };

  const getAllianceLocation = async () => {
    const locationData = await getLocationByPartyType(partyTypes[partyTypes.Alliances]);
    setAlliancesEventLocations(locationData);
  };

  const getOppositionLocation = async () => {
    const locationData = await getLocationByPartyType(partyTypes[partyTypes.Opposition]);
    setOppositionEventLocations(locationData);
  };

  const getLocationByPartyType = async (partyType: string, partyName?: string) => {
    const toDate = moment(moment.now()).format("YYYY-MM-DD");
    const fromDate = calculateFromDate(toDate, noOfMonths);

    const locationData = await getPartyLocations(fromDate, toDate, partyType, currentConstituency, partyName);
    return locationData;
  };

  const getButtonControlsOptions = () => {
    return [
      {
        label: "3 " + getWord("Months"),
        onClick: threeMonthOnClick,
      },
      {
        label: "6 " + getWord("Months"),
        onClick: sixMonthOnClick,
      },
      {
        label: "1 " + getWord("Year"),
        onClick: oneYearOnClick,
      },
    ];
  };

  const buttonControls = (data: any) => {
    return data.map((item: any, index: number) => {
      return (
        <button
          key={uuid()}
          className={`${activeIndex === index ? "calendarBtnActive" : ""} 
          ${index === 0 ? "rounded-l-lg" : ""} ${index === data.length - 1 ? "rounded-r-lg" : ""} 
            button hover:text-textWhite text-textBlack dark:text-textWhite dark:bg-darkSecondary dark:border-[#C6C6C64D] py-1 px-4`}
          onClick={() => item.onClick(index)}
        >
          {item.label}
        </button>
      );
    });
  };

  const getMapButtonOptions = () => {
    return [
      {
        label: "AC",
        onClick: oneYearOnClick,
      },
    ];
  };

  const getMapButtonControls = (data: any) => {
    return data.map((item: any, index: number) => {
      return (
        <button
          key={uuid()}
          className={`${mapActiveIndex === index ? "calendarBtnActive" : ""}          
          ${index === 0 ? "rounded-tl-lg" : ""} 
          ${index === data.length - 1 ? "rounded-tr-lg" : ""}
          mapButton hover:text-textWhite text-textBlack py-1 px-4`}
          onClick={() => item.onClick(index)}
        >
          {getWord(item.label)}
        </button>
      );
    });
  };

  const threeMonthOnClick = (activeIndex: any) => {
    setActiveIndex(activeIndex);
    setNoOfMonths(3);
  };

  const sixMonthOnClick = (activeIndex: any) => {
    setActiveIndex(activeIndex);
    setNoOfMonths(6);
  };

  const oneYearOnClick = (activeIndex: any) => {
    setActiveIndex(activeIndex);
    setNoOfMonths(12);
  };

  const constituencyMapOnClick = (event: any) => {
    const constituency = event.target.feature.properties.CONSTITUENCY;
    setCurrentConstituency(constituency);
    setSummaryTitle(constituency);
    setGeoJsonData(getGeoJson(constituency));
    setSelectedFeature(event.target);
    setCurrentBounds(event.target.getLatLngs());
    setPathOptions(getPathOptions(true));
    if (isSmallDevice || isIpadDevice) scrollIntoView(departmentCardRef.current, { behavior: "smooth" }, { duration: 1500 });
  };

  const resetOnClick = () => {
    setResetZoom(!resetZoom);
    if (currentConstituency.length <= 0) return;
    setCurrentBounds(null);
    setSelectedFeature(null);
    setPathOptions(navMapPathOptions);
    const data = getGeoJson();
    setConstituencyGeoJson(null); //temporarily set to null, In useEffect it will reset.
    setGeoJsonData(data);
    setCurrentConstituency("");
  };

  return (
    <div className={`flex flex-col mt-5 ml-5 mr-5 h-full calendarPage`}>
      <div className="grid sm:grid-cols-1 md:grid-cols-1 2md:grid-cols-1 mdi:grid-cols-1 lg:grid-cols-2 xl:grid-cols-12">
        <div className="flex flex-col card dark:darkCard mdi:col-span-4 xl:col-span-4 min-h-[90vh] max-h-[100vh] sm:min-h-[80vh] xs:min-h-[80vh] mdi:min-h-[80vh]">
          <div className="flex flex-col h-full">
            <div className="w-full grid">
              <div className=" mt-2 border-b-[1px] dark:border-[#707070] mx-5 h-fit">{getMapButtonControls(getMapButtonOptions())}</div>
              <div className="mt-3 mx-5 justify-self-end">{buttonControls(getButtonControlsOptions())}</div>
              <SummaryCard label={summaryTitle} data={allEventsData} />
              <div className="inline-flex mt-2 mx-6 mb-2 justify-end h-fit">
                <button
                  className={`resetButton rounded-lg hover:text-textWhite text-textBlack dark:text-textWhite dark:bg-darkSecondary dark:border-[#C6C6C64D] py-1 px-4 w-25`}
                  onClick={resetOnClick}
                >
                  <div className="flex">
                    <ResetIcon className="mt-1.5 mr-2" color={`${isAppDarkTheme() ? "white" : "black"}`} />
                    {"Reset"}
                  </div>
                </button>
              </div>
            </div>
            <div className="h-full">
              <LeafletMap
                geoData={constituencyGeoJson}
                pathOptions={navMapPathOptions}
                onClick={constituencyMapOnClickCallback}
                height={`98.5%`}
                selectedFeature={selectedFeature}
                currentBounds={currentBounds}
                availableFeatures={eventsAvailableCons}
                resetZoom={resetZoom}
                dragging={true}
                scrollWheelZoom={true}
              />
            </div>
          </div>
        </div>
        <div className="grid mdi:col-span-8 xl:col-span-8" ref={departmentCardRef}>
          <div className="grid sm:grid-cols-1 md:grid-cols-1 mdi:mt-5 2md:grid-cols-1 mdi:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 h-full">
            <div className="grid card dark:darkCard xs:mt-5 sm:mt-5 md:mt-5 xl:ml-5 2md:ml-5  2md:mt-0 h-full sm:min-h-[40vh] xs:min-h-[40vh] mdi:min-h-[40vh]">
              <div className="flex flex-col h-full">
                <div className="h-fit">
                  <CardHeader title={Department} count={departEventLocations.length} />
                  <div className="ml-3 mt-2 z-50">
                    <MultiSelect
                      options={departmentOptions}
                      value={selectedDepartment}
                      onChange={setSelectedDepartment}
                      labelledBy="Select"
                      ClearSelectedIcon={<></>}
                      overrideStrings={{
                        allItemsAreSelected: "All Departments",
                        selectAll: "All Departments",
                      }}
                    />
                  </div>
                </div>
                <div className="h-full">
                  <LeafletMap
                    className={"!bg-white dark:!bg-darkSecondary mb-2 z-0"}
                    geoData={geoJsonData}
                    pathOptions={pathOptions}
                    markerData={departEventLocations}
                    height={`100%`}
                    resetZoom={resetZoom}
                  />
                </div>
                <div className="mb-1 h-fit">{showGovLegends()}</div>
              </div>
            </div>
            <div className="grid card dark:darkCard ml-5 xs:ml-0 xs:mt-10 sm:mt-10 sm:ml-0 md:ml-0 md:mt-5 2md:mt-0 h-full sm:min-h-[40vh] xs:min-h-[40vh] ">
              <div className="flex flex-col h-full">
                <div className="flex flex-col h-fit">
                  <CardHeader title={Party} count={partyEventLocations.length} />
                </div>
                <div className="h-full">
                  <LeafletMap
                    geoData={geoJsonData}
                    pathOptions={pathOptions}
                    markerData={partyEventLocations}
                    markerColor={getMarkerColor(eventType.Party)}
                    height={`98.5%`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid sm:grid-cols-1 md:grid-cols-1 2md:grid-cols-1 lg:grid-cols-1 mdi:grid-cols-2 xl:grid-cols-2 mt-5 2md:mt-5 sm:mb-2 md:mb-2 mdi:mt-10">
            <div className="grid card dark:darkCard xl:ml-5 2md:ml-5 sm:min-h-[44vh] xs:min-h-[44vh] mdi:max-h-[44vh] xs:mt-10 sm:mt-10">
              <div className="flex flex-col h-full ">
                <div className="flex flex-col h-fit">
                  <CardHeader title={Alliances} count={alliancesEventLocations.length} />
                </div>
                <div className="h-full">
                  <LeafletMap
                    geoData={geoJsonData}
                    pathOptions={pathOptions}
                    markerData={alliancesEventLocations}
                    markerColor={getMarkerColor(eventType.Alliances)}
                    height={`98.5%`}
                  />
                </div>
              </div>
            </div>
            <div className="grid card dark:darkCard mt-5 mdi:ml-5 xl:ml-5 2xl:ml-5 mdi:mt-0 xl:mt-0 2xl:mt-0 sm:mb-[5vh] xs:mb-[5vh] mdi:mb-[5vh] sm:min-h-[44vh] xs:min-h-[44vh] mdi:min-h-[44vh]">
              <div className="flex flex-col h-full">
                <div className="flex flex-col h-fit">
                  <CardHeader title={Opposition} count={oppositionEventLocations.length} />
                </div>
                <div className="h-full">
                  <LeafletMap
                    geoData={geoJsonData}
                    pathOptions={pathOptions}
                    markerData={oppositionEventLocations}
                    markerColor={getMarkerColor(eventType.Opposition)}
                    height={`98.5%`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;
