import React, { FC, useState } from "react";
import { PaperCoverage as Paper_Coverage, Sentiment } from "../../utils/constants";
import NewsArticles from "../NewsArticles/NewsArticles";
import PaperCoverage from "../PaperCoverage/PaperCoverage";
import "./MediaSentiment.scss";

interface MediaSentimentProps {}

const MediaSentiment: FC<MediaSentimentProps> = () => {
  const [activeId, setActiveId] = useState<number>(0);
  const [title, setTitle] = useState<string>(Sentiment);

  const positiveOnClick = (event: any) => {
    setActiveId(Number(event.target.id));
    setTitle(Sentiment);
  };
  const negativeOnClick = (event: any) => {
    setActiveId(Number(event.target.id));
    setTitle(Paper_Coverage);
  };

  return (
    <div className="MediaSentiment h-full" data-testid="MediaSentiment">
      <div className="flex w-full h-fit justify-between">
        <div className="flex text-cardTitle h-fit dark:text-textWhite font-bold ml-5 mt-2">{title}</div>
        <div className="mt-5 border-[#867F7F80] border-[1px] rounded-lg mr-5">
          <button
            id="0"
            className={`hover:bg-[#5154D3] hover:text-textWhite dark:text-textWhite px-5 py-1 rounded-l-md ${activeId === 0 ? "activeBtn" : ""}`}
            onClick={positiveOnClick}
          >
            {Sentiment}
          </button>
          <button
            id="1"
            className={`hover:bg-[#5154D3] hover:text-textWhite dark:text-textWhite px-5 py-1 rounded-r-md ${activeId === 1 ? "activeBtn" : ""}`}
            onClick={negativeOnClick}
          >
            {Paper_Coverage}
          </button>
        </div>
      </div>
      <div className="h-[80%] xs:h-[85%] sm:h-[85%]">{activeId === 0 ? <NewsArticles /> : <PaperCoverage />}</div>
    </div>
  );
};

export default MediaSentiment;
