import { useQuery } from "@tanstack/react-query";
import React, { FC, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { PAPER_COVERAGE_DATA } from "../../api/helpers/DataQueryKeys";
import { getPaperCoverage } from "../../api/MediaServices";
import { BarChartOptionsProps, getBarChartOptions } from "../../utils/barChartUtility";
import { CM, DMK, Government, printMediaNoDataString } from "../../utils/constants";
import { getUserSelectedDate, getValidDate } from "../../utils/dateHelper";
import { getPaperCoverageLegends, ShowLegend, showText } from "../../utils/helper";
import BarChart from "../Charts/BarChart/BarChart";
import { getBgColor } from "../Charts/Helpers/ChartUtility";
import "./PaperCoverage.scss";

interface PaperCoverageProps {}

const PaperCoverage: FC<PaperCoverageProps> = () => {
  const [date] = useState(getValidDate(getUserSelectedDate(), "YYYY-MM-DD"));
  const [legends] = useState<any>(getPaperCoverageLegends());
  const [dataset, setDataset] = useState<any>();
  const { data: paperCoverage, isLoading } = useQuery([PAPER_COVERAGE_DATA, date], () => getPaperCoverage(date));
  const isSmallScreen = useMediaQuery({ maxWidth: 820 });

  useEffect(() => {
    if (paperCoverage) {
      const data = getDataSets(paperCoverage);
      setDataset(data);
    }
  }, [paperCoverage]);

  if (isLoading) {
    return <></>;
  }

  function getLabels() {
    if (!paperCoverage) return [];
    return Object.keys(paperCoverage?.data);
  }

  const getData = (index: any, paperList: any) => {
    if (!paperCoverage || !paperList) return [];

    return paperList.map((paper: any) => {
      return Number(paperCoverage.data[index].find((x: any) => x.paper === paper)?.count ?? 0);
    });
  };

  const getDataSets = (paperCoverage: any) => {
    if (!paperCoverage) return;
    const labels = getLabels();
    const datasets: any = [];
    labels.forEach((label: any) => {
      const data = {
        label: label,
        data: getData(label, paperCoverage.newsPaperList),
        barThickness: 15,
        backgroundColor: getBgColor(label),
        borderRadius: 3,
      };

      datasets.push(data);
    });

    return datasets;
  };

  const chartData = () => {
    return {
      labels: paperCoverage?.newsPaperList ?? [],
      datasets: dataset,
    };
  };

  const barChartProps: BarChartOptionsProps = {
    layout: isSmallScreen ? { padding: { top: 0, right: 30, bottom: 10, left: 10 } } : { padding: { top: 30, right: 30, bottom: 10, left: 30 } },
    dataLabels: { display: false },
    indexAxis: isSmallScreen ? "y" : "x",
    yTicksFont: isSmallScreen ? { size: 10 } : {},
  };

  const chartOptions = getBarChartOptions(barChartProps);

  return (
    <div className="PaperCoverage h-full" data-testid="PaperCoverage">
      <div className="inline-flex justify-end h-fit w-full mt-2">
        <ShowLegend legends={legends} showPercentage={false}></ShowLegend>
      </div>
      {dataset ? (
        <div className="h-full">
          <BarChart chartData={chartData()} chartOptions={chartOptions} />
        </div>
      ) : (
        showText(printMediaNoDataString)
      )}
    </div>
  );
};

export default PaperCoverage;
