import moment from "moment";
import uuid from "react-uuid";
import { getAppTheme } from "../api/AuthService";
import geoJsonData from "../assets/json/assembly-constituency.min.json";
import { MarkerIcon } from "../common/icons/MarkerIcon";
import { Color } from "../enums/color";
import { eventType } from "../enums/eventType";
import { getWord } from "../services/lang.services";
import { getAppLanguage } from "./authHelper";
import { Dark } from "./constants";
import { TailSpin } from "react-loader-spinner";
import { Colors } from "../components/Charts/Helpers/ChartUtility";

export const showText = (text: string, className?: string) => {
  return <div className={`grid justify-evenly text-subTitle dark:text-textWhite ${className ? className : "p-28"}`}>{text}</div>;
};

export const getPathOptions = (isHighlighted?: boolean) => {
  const isDarkTheme = isAppDarkTheme();
  const highlightedOpacity = 1;
  const highlightedColor = isDarkTheme ? Color.darkHighlightGrey : Color.highlightGrey;
  const fillOpacity = isDarkTheme ? 0.5 : 1;
  const fillColor = isDarkTheme ? Color.darkFillGrey : Color.fillGrey;
  return {
    color: isDarkTheme ? Color.darkBorderGrey : Color.borderGrey,
    fillColor: isHighlighted ? highlightedColor : fillColor,
    fillOpacity: isHighlighted ? highlightedOpacity : fillOpacity,
    opacity: isDarkTheme ? 0.8 : 1,
    weight: isDarkTheme ? 0.8 : 1,
  };
};

const getFeatureData = (constituency: string) => {
  const featureData: any = [];
  geoJsonData.features.forEach((item: any) => {
    if (item.properties.CONSTITUENCY === constituency) {
      featureData.push(item);
      return featureData;
    }
  });
  return featureData;
};

export const getGeoJson = (constituency?: string) => {
  if (!constituency) {
    return geoJsonData;
  }

  return {
    type: "FeatureCollection",
    features: getFeatureData(constituency),
  };
};

export const getMarkerIcon = (color?: string) => {
  color = color ? color : "black";
  return `<svg id="Component_5_364" data-name="Component 5 – 364" xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 14 20">
  <path id="Path_10821" data-name="Path 10821" d="M7,0a7,7,0,0,1,7,7c0,3.866-7,14.819-7,14.819S0,10.866,0,7A7,7,0,0,1,7,0Z" fill="${color}"/>
  <circle id="Ellipse_67" data-name="Ellipse 67" cx="4" cy="4" r="4" transform="translate(3 3)" fill="#fff"/>
</svg>`;
};

export const getDropDownOptions = (data?: any) => {
  if (!data) return [];
  return data.map((item: any) => {
    return {
      label: item,
      value: item,
    };
  });
};

export const getValueFromDropDownOptions = (data?: any) => {
  if (!data) return [];
  return data.map((item: any) => item.value.trim());
};

export const calculateFromDate = (toDate: any, noOfMonths: number) => {
  const fromDate = moment(new Date(toDate)).subtract(noOfMonths, "months").format("YYYY-MM-DD");
  return fromDate;
};

export const getProgressBarOption = (width: number, event?: eventType) => {
  switch (event) {
    case eventType.Department:
      return {
        width: width,
        color: "#07C2D1",
      };
    case eventType.Party:
      return {
        width: width,
        color: "#F54A4A",
      };
    case eventType.Alliances:
      return {
        width: width,
        color: "#7E5CE6",
      };
    case eventType.Opposition:
      return {
        width: width,
        color: "#FF9E58",
      };
    default:
      return {
        width: width,
        color: "#D5D2D0",
      };
  }
};

export const getProgressBarOptions = (count: number, total: number, event: eventType) => {
  const options: any = [];

  count = Math.ceil((count / total) * 100);
  total = 100 - count;

  options.push(getProgressBarOption(count, event));
  options.push(getProgressBarOption(total));
  return options;
};

export const getAllProgressBarOptions = (data: any, total: number) => {
  const options: any = [];

  data.map((item: any) => {
    if (item.name !== "Total") {
      options.push(getProgressBarOption(Math.ceil((item.count / total) * 100), getEventType(item.name)));
    }
  });
  return options;
};

export const getEventType = (name?: string) => {
  if (name === "Alliances") return eventType.Alliances;
  else if (name === "Opposition") return eventType.Opposition;
  else if (name === "Department") return eventType.Department;
  else if (name === "Party" || name === "DMK") return eventType.Party;
  else return eventType.Default;
};

export const getCount = (data: any, event: eventType) => {
  return data ? data.filter((item: any) => getEventType(item.name) === event)[0]?.count : 0;
};

export const getMarkerColor = (event?: eventType) => {
  if (event === eventType.Department) return "#07C2D1";
  else if (event === eventType.Party) return "#F54A4A";
  else if (event === eventType.Alliances) return "#7E5CE6";
  else if (event === eventType.Opposition) return "#FF9E58";
  else return "#AF1212CC"; //CM event color
};

export const getEventTypes = () => {
  return [eventType[eventType.Alliances], eventType[eventType.Opposition], eventType[eventType.Department], eventType[eventType.Party]];
};

export const getLegendOptions = (event: eventType) => {
  const options: any = [];
  if (event === eventType.Department) {
    options.push({
      markerColor: getMarkerColor(),
      label: "CM",
    });
    options.push({
      markerColor: getMarkerColor(eventType.Department),
      label: "Department",
    });
  }
  return options;
};

export const showGovLegends = () => {
  const governmentLegends = getLegendOptions(eventType.Department);
  return (
    <div className="inline-flex">
      {governmentLegends.map((item: any) => {
        return (
          <div key={uuid()} className="flex ml-5">
            <div className="mt-1.5">
              <MarkerIcon color={item.markerColor} />
            </div>
            <h1 className="ml-1 text-textBlack text-subTitle dark:text-textWhite">{getWord(item.label)}</h1>
          </div>
        );
      })}
    </div>
  );
};

export const isAppDarkTheme = () => {
  return getAppTheme() === Dark;
};

export function isTamilLang() {
  return getAppLanguage() === "ta";
}

type ShowLegendProps = {
  legends: string[];
  data?: any;
  showPercentage?: boolean;
  showOrder?: string;
};

function getBgColor(label: string) {
  switch (label) {
    case "Positive":
      return "bgPositive";
    case "Negative":
    case "DMK":
      return "bgNegative";
    case "Neutral":
      return "bgNeutral";
    case "Gov":
    case "Government":
      return "bgGov";
    case "CM":
      return "bgCM";
    case "AIADMK":
    case "ADMK":
      return "bgAdmk";
    case "BJP":
      return "bgBjp";
    case "Others":
      return "bgOthers";
    case "Break time":
      return "bgBreak";
    case "Event time":
      return "bgEvent";
    default:
      return "bgOthers";
  }
}

function getLabel(label: string) {
  if (label === "Government") return "Gov";
  return label;
}

export const ShowLegend: React.FC<ShowLegendProps> = (props) => {
  const showPercentage = props.showPercentage === undefined ? true : props.showPercentage;
  const isVertical = props.showOrder === "row";

  return (
    <div className="">
      {props.legends.map((item: any) => (
        <div key={uuid()} className={`${isVertical ? "flex p-1 space-x-5" : "inline-flex justify-evenly mr-8"} items-center`}>
          <div className={` ${getBgColor(item)} px-1 w-[10%] border-[#70707080] border-[1px] h-1`} />
          <div className="w-full mx-2 text-start text-subTitle dark:text-textWhite">{getLabel(item)}</div>
          {showPercentage ? <div className={`text-subTitle font-bold dark:text-textWhite`}>{(props.data[item] || 0) + "%"}</div> : <></>}
        </div>
      ))}
    </div>
  );
};

export const showLoadingIcon = () => {
  return (
    <div className="grid justify-evenly p-28">
      <TailSpin color={Colors.deepBlue} height={50} width={50} />
    </div>
  );
};

export const getPaperCoverageLegends = () => {
  return ["DMK", "CM", "Government", "Other Parties"];
};
