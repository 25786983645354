import React, { FC, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { NEWS_ARTICLES_DATA } from "../../api/helpers/DataQueryKeys";
import { getNewsArticles } from "../../api/MediaServices";
import BarChart from "../Charts/BarChart/BarChart";
import "./NewsArticles.scss";
import { BarChartOptionsProps, getBarChartOptions } from "../../utils/barChartUtility";
import { getUserSelectedDate, getValidDate } from "../../utils/dateHelper";
import { ShowLegend, showText } from "../../utils/helper";
import { getSentimentLegend } from "../../utils/sentimentUtility";
import { DMK, Government, Negative, Neutral, Positive, printMediaNoDataString } from "../../utils/constants";
import { getBgColor } from "../Charts/Helpers/ChartUtility";
import { useMediaQuery } from "react-responsive";

interface NewsArticlesProps {
  date?: Date;
}

const NewsArticles: FC<NewsArticlesProps> = (props) => {
  const [date] = useState(getValidDate(getUserSelectedDate(), "YYYY-MM-DD"));
  const [subjects] = useState<string[]>([DMK, Government]);
  const { data: newsArticles } = useQuery([NEWS_ARTICLES_DATA, subjects, date], () => getNewsArticles(subjects, date));
  const isSmallScreen = useMediaQuery({ maxWidth: 820 });

  const chartData = {
    labels: newsArticles?.map((item: any) => {
      let index = item.name.indexOf(" ");
      let name: string[] = [];
      if (index >= 0) {
        name.push(item.name.substring(0, index));
        name.push(item.name.substring(index + 1, item.name.length));
        return name;
      } else {
        return item.name;
      }
    }),
    datasets: [
      {
        label: Negative,
        data: newsArticles ? newsArticles.map((item: any) => Number(item.Negative) || 0) : [0],
        stack: Negative,
        barThickness: 5,
        backgroundColor: getBgColor(Negative),
        borderRadius: 10,
      },
      {
        label: Neutral,
        data: newsArticles ? newsArticles.map((item: any) => Number(item.Neutral) || 0) : [0],
        stack: Neutral,
        barThickness: 5,
        backgroundColor: getBgColor(Neutral),
        borderRadius: 10,
      },
      {
        label: Positive,
        data: newsArticles ? newsArticles.map((item: any) => Number(item.Positive) || 0) : [0],
        stack: Positive,
        barThickness: 5,
        backgroundColor: getBgColor(Positive),
        borderRadius: 10,
      },
    ],
  };

  const barChartProps: BarChartOptionsProps = {
    stepSize: Math.round(chartData ? Math.max(...chartData.datasets[0].data) / 3 : 5),
    layout: isSmallScreen ? { padding: { top: 0, right: 30, bottom: 10, left: 10 } } : { padding: { top: 30, right: 30, bottom: 10, left: 30 } },
    dataLabels: { display: false },
    indexAxis: isSmallScreen ? "y" : "x",
    yTicksFont: isSmallScreen ? { size: 10 } : {},
  };

  const chartOptions = getBarChartOptions(barChartProps);

  return (
    <div className="MediaSentiment h-full" data-testid="MediaSentiment">
      <div className="flex justify-end h-fit w-full mt-2">
        <ShowLegend data={chartData} legends={getSentimentLegend()} showPercentage={false}></ShowLegend>
      </div>

      {newsArticles ? (
        <div className="h-full">
          <BarChart chartData={chartData} chartOptions={chartOptions} />
        </div>
      ) : (
        showText(printMediaNoDataString)
      )}
    </div>
  );
};

export default NewsArticles;
