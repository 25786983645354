import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { FC, useState } from "react";
import { TWEETS_SENTIMENT_DATA } from "../../api/helpers/DataQueryKeys";
import { getTweetsSentiment } from "../../api/SocialMediaServices";
import { noDataString } from "../../utils/constants";
import { convertToUTC, getUserSelectedDate } from "../../utils/dateHelper";
import { isAppDarkTheme, ShowLegend, showText } from "../../utils/helper";
import { createSentimentChartData, getSentimentChartOption, getSentimentLegend } from "../../utils/sentimentUtility";
import DoughnutChart from "../Charts/DoughnutChart/DoughnutChart";
import { Colors } from "../Charts/Helpers/ChartUtility";
import LiveChart from "../Charts/LiveChart/LiveChart";
import "./TwitterSentiment.scss";

interface TwitterSentimentProps {
  title: string;
}

const getPreviousDates = (last: number, dateFormat?: string): any => {
  return [...Array(last)].map((_, i) => {
    const d = new Date();
    d.setDate(d.getDate() - (last - 1));
    d.setDate(d.getDate() + i);
    return moment(d).format(dateFormat || "YYYY-MM-DD");
  });
};

const positive = "Positive";
const negative = "Negative";

const getDataSet = (data: any, date: string, label: string) => {
  let value = null;
  if (data) {
    const val = data.find((x: any) => x[date]);
    value = (val && val[date][label]) || 0;
  }
  return value;
};

const getListOfDataSet = (data: any, label: string) => {
  let dataSets = null;
  if (data) {
    const dateList = getPreviousDates(7);
    dataSets = dateList.map((date: string) => getDataSet(data, date, label));
  }
  return dataSets;
};

const getOneWeekChartData = (data: any) => {
  if (!data) return null;
  return {
    labels: getPreviousDates(7, "MMM DD"),
    datasets: [
      {
        label: positive,
        data: getListOfDataSet(data, positive),
        borderColor: Colors.chartPositive,
        backgroundColor: Colors.chartPositive,
        cubicInterpolationMode: "monotone" as any,
        pointRadius: 3,
        responsive: true,
        pointBackgroundColor: Colors.white,
      },
      {
        label: negative,
        data: getListOfDataSet(data, negative),
        borderColor: Colors.chartNegative,
        backgroundColor: Colors.chartNegative,
        cubicInterpolationMode: "monotone" as any,
        pointRadius: 3,
        responsive: true,
        pointBackgroundColor: Colors.white,
      },
    ],
  };
};

const TwitterSentiment: FC<TwitterSentimentProps> = (props: TwitterSentimentProps) => {
  const [userDate] = useState(getUserSelectedDate());
  const currentDate = userDate ? new Date(userDate.getTime()) : new Date();

  const toDate = convertToUTC({ date: currentDate, setZeroSec: true });
  currentDate.setHours(currentDate.getHours() - 1);
  const fromDateWithHrs = convertToUTC({ date: currentDate, setZeroSec: true });
  const fromDate = convertToUTC({ date: currentDate, strFormat: "YYYY-MM-DD" });
  const { data: onedayData, isLoading, error } = useQuery([TWEETS_SENTIMENT_DATA, 1], () => getTweetsSentiment(fromDateWithHrs, toDate));

  currentDate.setHours(currentDate.getHours() + 1);
  currentDate.setDate(currentDate.getDate() - 6);
  const fromDate7 = convertToUTC({ date: currentDate, setZeroSec: true });
  const {
    data: oneWeekData,
    isLoading: oneWeekDataIsLoading,
    error: oneWeekDataError,
  } = useQuery([TWEETS_SENTIMENT_DATA, 7], () => getTweetsSentiment(fromDate7, toDate));

  if (isLoading || oneWeekDataIsLoading) {
    return <></>;
  }

  if (error || oneWeekDataError) {
    return <></>;
  }

  const timeCharOption = () => {
    const isDarkTheme = isAppDarkTheme();
    return {
      plugins: {
        tooltip: {
          enabled: true,
        },
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
        scales: {
          y: {
            ticks: {
              stepSize: 50,
            },
          },
          x: {
            type: "time",
            time: {
              unit: "day",
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
      scales: {
        y: {
          border: {
            display: false,
          },
          ticks: {
            color: isDarkTheme ? Colors.white : Colors.black,
          },
          min: 0,
          grid: {
            color: isDarkTheme ? Colors.darkThemeLightGray : Colors.lightGray,
            drawTicks: false,
          },
          title: {
            display: true,
            text: "Percentage(%)",

            color: isDarkTheme ? Colors.darkThemeLightGray : Colors.lightGray,
          },
        },
        x: {
          ticks: {
            color: isDarkTheme ? Colors.white : Colors.black,
          },
          grid: {
            color: "",
          },
        },
      },
      layout: { padding: { top: 30, right: 30, bottom: 20, left: 20 } },
    };
  };

  const oneDaySentiment: any | null = onedayData && onedayData.length > 0 ? (onedayData as any)[0][fromDate] : null;

  const oneDayChartData = oneDaySentiment ? createSentimentChartData(oneDaySentiment, "60%") : null;
  const chartOption = getSentimentChartOption();

  const oneWeekChartData = oneWeekData ? getOneWeekChartData(oneWeekData) : null;

  return (
    <div className="TweetSentiment h-full w-full" data-testid="TweetSentiment">
      <h1 className="text-cardTitle dark:text-textWhite font-bold ml-5 mt-2"> {props.title} </h1>

      <div className="w-full h-full">
        <div className="flex w-full h-1/3 mt-5">
          <div className="w-full h-full">
            {oneDayChartData ? (
              <div className="flex h-full items-center justify-evenly w-full">
                <DoughnutChart chartData={oneDayChartData} options={chartOption} className={"items-center"}></DoughnutChart>
                <ShowLegend showOrder="row" data={oneDaySentiment} legends={getSentimentLegend()}></ShowLegend>
              </div>
            ) : (
              showText(noDataString, "p-16")
            )}
          </div>
        </div>

        <div className="w-full h-1/2 grid justify-items-center">
          {oneWeekChartData ? (
            <LiveChart className={"h-full  w-[100%] grid"} chartData={oneWeekChartData} options={timeCharOption()} applyGradient={true}></LiveChart>
          ) : (
            showText(noDataString, "p-20")
          )}
        </div>
      </div>
    </div>
  );
};

export default TwitterSentiment;
